/* eslint no-restricted-globals:0 */
import React, { useState, useEffect, Fragment } from 'react'
import moment from 'moment'
import Immutable from 'seamless-immutable'
import _ from 'lodash'
import { WithContext as ReactTags } from 'react-tag-input'
import { Container, Row, Col, CustomInput, FormGroup, Input } from 'reactstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from 'react-router-dom'
import Loading from './Loading'
import Alert from './Alert'
import Head from './Head'
import Pagination from './Pagination'

const DataList = ({ items, wavesById, typesById, tagsById, offset, categoriesById, onEdit, onDelete }) => {
  let { path, url } = useRouteMatch()
  return (

    <table className='table'>
      <thead>
        <tr>
          <th scope='col'>ID</th>
          <th scope='col'>Email</th>
          <th scope='col'>First Name</th>
          <th scope='col'>Last Name</th>
          <th scope='col'>Created Date</th>
          <th scope='col' />
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => {
          return (<tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.username}</td>
            <td>{item.firstName}</td>
            <td>{item.lastName}</td>
            
            <td>{moment(item.createdAt).format('MMM DD, YY')}</td>
            <td>
              <div>
                <Link className='btn btn-info btn-sm mr-1' to={`${url}/view/${item.id}`}>View</Link>
                <a className='btn btn-sm btn-outline-info' href='#' onClick={() => onDelete(item)}>Delete</a>
              </div>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  )
}
const UserFilter = ({ handleFilter, onFilterChange, keyword }) => {
  return (
    <form className='form-inline'>
      <div className='form-group mx-sm-3 mb-2'>
        <label htmlFor='keyword' className='sr-only'>Keyword</label>
        <input type='text' name='keyword' value={keyword} onChange={onFilterChange} className='form-control' id='keyword' placeholder='keyword' />
      </div>
      <button className='btn btn-primary mb-2' onClick={handleFilter}>Search</button>
    </form>
  )
}
const UsersList = ({fetchUsers, totalCount, items, title, deleteUser, fetching, error, dispatch}) => {
  const ITEM_PER_PAGE = 25
  const [message, setMessage] = useState({type: '', message: ''})
  const [pagInfo, setPagInfo] = useState({ offset: 0, limit: ITEM_PER_PAGE })
  const [keyword, setKeyword] = useState('')
  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteUser(item).then(() => {
        setMessage({type: 'success', message: 'Deleted successfully.'})
      }).catch((err) => {
        setMessage({type: 'error', message: err.message})
      })
    }
  }
  const fetchData = () => {
    const { offset, limit } = pagInfo
    console.log({ keyword, offset, limit })
    fetchUsers({ keyword, offset, limit})
  }
  const onPageChange = (offset) => {
    setPagInfo({...pagInfo, offset })
  }
  const onKeywordChange = (e) => {
    setKeyword(e.target.value.trim())
  }
  const onFilterClick = (e) => {
    fetchData()
  }
  const handleFilter = (e) => {
    e.preventDefault()
    setPagInfo({...pagInfo, offset: 0})
  }

  useEffect(() => {
    fetchData()
  }, [pagInfo])

  let { path, url } = useRouteMatch()

  return (
    <div>
      <Head>{title}</Head>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <Container>
          <Row>
            <Col>
              <Loading fetching={fetching} />
              <Alert color={message.type}>{ message.message }</Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={`${url}/edit`} className='btn btn-primary float-right d-none'>Add User</Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <UserFilter keyword={keyword} handleFilter={handleFilter} onFilterChange={onKeywordChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='media text-muted pt-3'>
                <DataList {...{items, onDelete: handleDelete, offset: pagInfo.offset}} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Pagination {...{ onPageChange, totalCount, offset: pagInfo.offset, limit: pagInfo.limit }} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default UsersList
