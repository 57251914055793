/* eslint no-restricted-globals:0 */
import React, {useState} from 'react';
import Immutable from 'seamless-immutable';
import {validateRoutineForm} from '../Lib/Validation';

import {TabContent, TabPane, Nav, NavItem, NavLink, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import Message from '../Lib/Message';

import Loading from './Loading';
import Alert from './Alert';
import QuestionForm from './QuestionFormv2';
import Head from './Head';
import QuestionList from './QuestionListv2';

const Routine = ({amQuestions, pmQuestions, title, saveItem, deleteItem, fetching, types, typesById}) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setIsEditing(false);
      setEditingItem(form.model);
    }
  };

  const answerEmpty = {
    id: '',
    text: '',
    weight: '',
    frequency: '',
    types: [],
    range_start: '',
    range_end: '',
  };

  const form = {
    model: {id: '', question: '', answers: [answerEmpty]},
    valid: false,
    dirty: false,
  };
  const [message, setMessage] = useState({type: '', message: ''});
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(form.model);
  const showMessage = Message.showMessage(setMessage);

  const routine = activeTab == '1' ? 'AM' : 'PM';

  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem({item, routine})
        .then(() => {
          showMessage({type: 'success', message: 'Deleted successfully.'});
          setIsEditing(false);
          setEditingItem(form.model);
        })
        .catch((err) => {
          showMessage({type: 'error', message: err.message});
        });
    }
  };
  const onEditItem = (item) => {
    setIsEditing(true);
    item = Immutable.asMutable(item, {deep: true});
    setEditingItem(item);
  };
  const handleSave = (event) => {
    event.preventDefault();

    let validationResult = validateRoutineForm(editingItem);
    if (!validationResult.isValid) {
      console.log(validationResult);
      // display messages
      showMessage({type: 'error', message: validationResult.printMessage});

      return;
    }
    saveItem({item: editingItem, routine})
      .then((res) => {
        setIsEditing(false);
        setEditingItem(form.model);
        showMessage({type: 'info', message: 'Saved successfully'});
      })
      .catch((err) => {
        showMessage({type: 'error', message: err.message});
      });
  };
  const handleAddAnswer = () => {
    editingItem.answers.push(answerEmpty);
    setEditingItem({...editingItem});
  };
  const handleRemoveAnswer = (index) => {
    editingItem.answers.splice(index, 1);
    setEditingItem({...editingItem});
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditingItem(form.model);
  };

  return (
    <div>
      <Head>{title}</Head>

      <div className="my-3 p-3 bg-white rounded shadow-sm">
        <Loading fetching={fetching} />
        <Alert color={message.type}>{message.message}</Alert>
        <Nav pills>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({active: activeTab === '1'})}
              onClick={() => {
                toggle('1');
              }}
            >
              AM
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              className={classnames({active: activeTab === '2'})}
              onClick={() => {
                toggle('2');
              }}
            >
              PM
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col md="8">
                <QuestionForm
                  {...{
                    editingItem,
                    form,
                    fetching,
                    handleSave,
                    handleCancel,
                    setEditingItem,
                    isEditing,
                    types,
                    typesById,
                    handleAddAnswer,
                    handleRemoveAnswer,
                  }}
                />
              </Col>
              <Col md="4">
                <QuestionList items={amQuestions} onDelete={handleDelete} onEdit={onEditItem} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col md="8">
                <QuestionForm
                  {...{
                    editingItem,
                    form,
                    fetching,
                    handleSave,
                    handleCancel,
                    setEditingItem,
                    isEditing,
                    types,
                    typesById,
                    handleAddAnswer,
                    handleRemoveAnswer,
                  }}
                />
              </Col>
              <Col md="4">
                <QuestionList {...{types, typesById}} items={pmQuestions} onDelete={handleDelete} onEdit={onEditItem} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default Routine;
