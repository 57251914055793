import { call, put, select } from 'redux-saga/effects'
import UserActions from '../Redux/UserRedux'
import { UserSelectors } from '../Redux/UserRedux'

export function * fetchUsers (api, action) {
  const { limit, offset, keyword } = action
  yield put(UserActions.fetchUsersRequest())
  try {
    const response = yield call(api.list, { limit, offset, keyword })
    console.log({ response })
    const { totalCount, users } = response.data.users
    yield put(UserActions.fetchUsersSuccess({ totalCount, users }))
  } catch (error) {
    console.log(error)
    yield put(UserActions.fetchUsersFailure())
  }
}
export function * deleteUser (api, action) {
  const { payload, resolve, reject } = action
  const id = payload.id
  console.log({ id })
  yield put(UserActions.deleteUserRequest({ id }))
  try {
    yield call(api.delete, id)
    yield put(UserActions.deleteUserSuccess({ id }))
    yield call(resolve)
  } catch (error) {
    yield put(UserActions.deleteUserFailure())
      console.error(error) // eslint-disable-line
    yield call(reject, error)
  }
}
export function * registerUser (api, action) {
  const { firstName, lastName, birthday, gender } = action
  yield put(UserActions.userSave({ firstName, lastName, birthday, gender }))
  const currentUser = yield select(UserSelectors.selectUser)

  if (currentUser && currentUser.firstName) {
    yield put(UserActions.userRegisterSuccess({ firstName, lastName, birthday, gender }))
  } else {
    yield put(UserActions.userRegisterFailure())
  }
}

export function * checkUser (api, action) {
  const currentUser = yield select(UserSelectors.selectUser)
  const { firstName, lastName, birthday, gender } = currentUser
  yield put(UserActions.checkUserComplete({ firstName, lastName, birthday, gender }))
}
