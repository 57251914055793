import { connect } from 'react-redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import NodeListHelp from '../Components/NodeListHelp'
import NodeActions, { NodeSelectors } from '../Redux/NodeRedux'

const mapStateToProps = (state, ownProps) => {
    return {
        nodeType: 'help',
        title: 'Help Text',
        items: NodeSelectors.selectHelp(state),
        fetching: state.node.fetching,
        error: state.node.error
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteItem: bindActionToPromise(dispatch, NodeActions.deleteHelpNode),
    saveItem: bindActionToPromise(dispatch, NodeActions.saveHelpNode)
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NodeListHelp)