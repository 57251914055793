import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import MeditationForm from '../Components/MeditationForm'
import NodeActions, { NodeSelectors } from '../Redux/NodeRedux'
import MeditationActions, { MeditationSelectors } from '../Redux/MeditationRedux'

const mapStateToProps = (state, ownProps) => {
  return {
    title: 'Meditations',
    error: state.meditation.error,
    fetching: state.meditation.fetching,
    items: MeditationSelectors.selectData(state),
    itemsById: MeditationSelectors.selectById(state),
    tags: NodeSelectors.selectTags(state),
    categories: NodeSelectors.selectCategories(state),
    types: NodeSelectors.selectTypes(state),
    waves: NodeSelectors.selectWaves(state),
    languages: NodeSelectors.selectLanguages(state),
    tagsById: NodeSelectors.selectTagsById(state),
    tagsOptions: NodeSelectors.selectTagsOptions(state),

    categoriesById: NodeSelectors.selectCategoriesById(state),
    typesById: NodeSelectors.selectTypesById(state),
    wavesById: NodeSelectors.selectWavesById(state)

        // feeling: MyFeelingSelectors.selectData(state)
  }
}
const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionToPromise(dispatch, MeditationActions.deleteMeditation),
  saveItem: bindActionToPromise(dispatch, MeditationActions.saveMeditation)
})

const MeditationFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MeditationForm)

export default MeditationFormContainer
