import {call, put} from 'redux-saga/effects';
import RoutineActions from '../Redux/RoutineRedux';

export function* deleteQuestion(api, action) {
  const {
    payload: {item, routine},
    resolve,
    reject,
  } = action;
  yield put(RoutineActions.deleteRoutineRequest({item, routine}));
  try {
    yield call(api.delete, {item, routine});
    yield put(RoutineActions.deleteRoutineSuccess({item, routine}));
    yield call(resolve);
  } catch (error) {
    yield put(RoutineActions.deleteRoutineFailure());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
export function* saveQuestion(api, action) {
  const {
    payload: {item, routine},
    resolve,
    reject,
  } = action;
  const {id, question, answers} = item;
  console.log('item: ');
  console.log({item, routine});
  yield put(RoutineActions.saveRoutineRequest({item, routine}));
  try {
    const response = yield call(api.save, {item: {id, question, answers}, routine});
    const {message, error, item} = response.data.saveQuestion;
    if (error) {
      throw new Error(message);
    }
    yield put(RoutineActions.saveRoutineSuccess({item, routine}));
    yield call(resolve, {item, routine});
  } catch (error) {
    yield put(RoutineActions.saveRoutineFailure());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
