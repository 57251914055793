import axios from 'axios';
import _ from 'lodash';
import * as Promise from 'bluebird';
const gql = require('graphql-tag');

const UPLOAD_URL_MUTATION = gql`
  mutation uploadUrl($key: String!) {
    uploadUrl(key: $key) {
      url
      fields {
        key
        bucket
        algorithm
        credential
        date
        policy
        signature
      }
    }
  }
`;
const ID = (prefix = '_') => {
  return prefix + Math.random().toString(36).substr(2, 6);
};
const handleFileSelect = (evt, s3Namespace = 'media') => {
  return new Promise((resolve, reject) => {
    let file = evt.target.files[0];
    if (!file) {
      reject('No file selected');
    } else {
      let fileName = prettyFileName(file.name);
      resolve({
        file: file,
        key: `${s3Namespace}/${fileName}`,
        filename: fileName,
        realName: file.name,
        mimetype: file.type,
        target: evt.target,
      });
    }
  });
};
const fileSelectToBase64 = (evt) => {
  return new Promise((resolve) => {
    let file = evt.target.files[0];
    var reader = new FileReader();
    reader.onload = (function (theFile) {
      return function (e) {
        let binaryData = e.target.result;
        let base64String = window.btoa(binaryData);
        resolve({
          encoding: base64String,
          filename: theFile.name,
          mimetype: theFile.type,
        });
      };
    })(file);
    reader.readAsBinaryString(file);
  });
};
const prettyFileName = (file) => {
  let fileParts = file.split('.');
  let ext = fileParts.pop();
  if (ext) {
    ext = '.' + ext.toLowerCase();
  }
  let fileName = _.kebabCase(fileParts.join('-')) + ID() + ext;
  return fileName;
};

const upload = async (uploadUrl, file, mimetype) => {
  const {
    url,
    fields: {key, acl, bucket, algorithm, credential, date, policy, signature},
  } = uploadUrl;
  var formData = new FormData();
  formData.append('key', key);
  formData.append('acl', acl);
  formData.append('Content-Type', mimetype);
  formData.append('bucket', bucket);
  formData.append('X-Amz-Algorithm', algorithm);
  formData.append('X-Amz-Credential', credential);
  formData.append('X-Amz-Date', date);
  formData.append('Policy', policy);
  formData.append('X-Amz-Signature', signature);
  formData.append('file', file);

  const postResult = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return postResult;
};

const proccessMeditationFiles = async (uploadApi, mutationItem) => {
  let filesUploading = [];
  if (_.isObject(mutationItem.image)) {
    filesUploading.push(mutationItem.image);
  }
  mutationItem.files.forEach((file) => {
    if (file) {
      let {url} = file;
      if (_.isObject(url)) {
        filesUploading.push(url);
      }
    }
  });
  const result = await Promise.each(filesUploading, async (fileItem) => {
    const {key, file, mimetype} = fileItem;
    const {data} = await uploadApi.getUploadUrl(key, mimetype);
    const {uploadUrl} = data || {};
    if (uploadUrl && uploadUrl.url && file) {
      console.log(' BEING UPLOAD FILE: ' + key);
      const res = await upload(uploadUrl, file, mimetype);
      console.log(res);
      console.log(' DONE UPLOAD FILE: ' + key);
      return res;
    }
  });
  if (_.isObject(mutationItem.image)) {
    mutationItem.image = mutationItem.image.key;
  }
  mutationItem.files.forEach((file) => {
    if (file && _.isObject(file.url)) {
      file.url = file.url.key;
    }
  });
  return mutationItem;
};
const proccessNodeImage = async (uploadApi, mutationItem) => {
  let filesUploading = [];
  if (_.isObject(mutationItem.image)) {
    filesUploading.push(mutationItem.image);
  }
  const result = await Promise.each(filesUploading, async (fileItem) => {
    const {key, file, mimetype} = fileItem;
    const {data} = await uploadApi.getUploadUrl(key, mimetype);
    const {uploadUrl} = data || {};
    if (uploadUrl && uploadUrl.url && file) {
      console.log(' BEING UPLOAD FILE: ' + key);
      const res = await upload(uploadUrl, file, mimetype);
      console.log(res);
      console.log(' DONE UPLOAD FILE: ' + key);
      return res;
    }
  });
  if (_.isObject(mutationItem.image)) {
    mutationItem.image = mutationItem.image.key;
  }

  return mutationItem;
};

export {
  upload,
  proccessMeditationFiles,
  proccessNodeImage,
  prettyFileName,
  handleFileSelect,
  fileSelectToBase64,
  UPLOAD_URL_MUTATION,
};
