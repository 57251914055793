import React from 'react';
import axios from 'axios';
const HandleS3UploadHook = () => {
  const [loading, setLoading] = React.useState(false);
  const handleS3Upload = async (uploadUrl, file, mimetype) => {
    setLoading(true);
    const {
      url,
      fields: {key, acl, bucket, algorithm, credential, date, policy, signature},
    } = uploadUrl;
    var formData = new FormData();
    formData.append('key', key);
    formData.append('acl', acl);
    formData.append('Content-Type', mimetype);
    formData.append('bucket', bucket);
    formData.append('X-Amz-Algorithm', algorithm);
    formData.append('X-Amz-Credential', credential);
    formData.append('X-Amz-Date', date);
    formData.append('Policy', policy);
    formData.append('X-Amz-Signature', signature);
    formData.append('file', file);

    const postResult = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    setLoading(false);
    return postResult;
  };
  return {
    handleS3Upload,
    loading,
  };
};

export default HandleS3UploadHook;
