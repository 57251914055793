import React, { Component } from 'react'
import Select from 'react-select'
import { getIntVal } from '../Lib/Helpers'

export default class QuestionForm extends Component {
  render () {
    const { editingItem, form, fetching, handleSave, handleCancel, setEditingItem, isEditing, handleAddAnswer, handleRemoveAnswer, types, typesById } = this.props
    const options = types.asMutable().map(item => {
      return { value: item.id, label: item.text }
    })

    return (
      <div className='pt-3 mb-0'>
        <form className='form' autoComplete='off'>
          <div className='form-group d-none'>
            <label htmlFor='id'>ID</label>
            <input type='hidden' value='1' />
            <input type='text' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => setEditingItem({...editingItem, id: event.target.value})} value={editingItem.id} />
          </div>
          <div className='form-group mb-3'>
            <label>Question</label>
            <textarea className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => setEditingItem({...editingItem, question: event.target.value})} value={editingItem.question} rows='2' />
          </div>
          <div className='form-group mb-3'>
            <label>Answers</label>
            {editingItem.answers.map((item, index) => {
            //   if (!item.id) {
            //     setEditingItem(prevState => {
            //       const answers = prevState.answers
            //       //answers[index].id = index + 1
            //       return { ...prevState, answers: answers}
            //     })
            //   }
              const defaultValue = options.filter(item => {
                return editingItem.answers[index].types && editingItem.answers[index].types.includes(item.value)
              })

              return (
                <div key={index} className={index % 2 == 0 ? 'border-top bg-light' : ''}>
                  <div className={'d-flex flex-row pt-2 pb-2 mb-1'}>
                    <div className='pb-1 mr-2'>
                      <input type='text' className='form-control' value={item.text} onChange={(e) => {
                        e.persist()
                        const val = e.target.value
                        setEditingItem(prevState => {
                          const answers = prevState.answers
                          answers[index].text = val
                          return { ...prevState, answers: answers}
                        })
                      }} />
                    </div>

                    <div className='pb-1 mr-2'>
                      <input type='text' placeholder='weight' className='form-control' value={item.weight} onChange={(e) => {
                        e.persist()
                        const val = getIntVal(e.target.value)
                        setEditingItem(prevState => {
                          const answers = prevState.answers
                          answers[index].weight = val
                          return { ...prevState, answers: answers}
                        })
                      }} />
                    </div>
                    <div className='pb-1 mr-2'>
                      <input type='text' placeholder='frequency' className='form-control' value={item.frequency} onChange={(e) => {
                        e.persist()
                        const val = getIntVal(e.target.value)
                        setEditingItem(prevState => {
                          const answers = prevState.answers
                          answers[index].frequency = val
                          return { ...prevState, answers: answers}
                        })
                      }} />
                    </div>

                    <div className='pb-1'>
                      <button type='button' className={index == 0 ? 'd-none' : 'btn btn-outline-info btn-sm'} onClick={(e) => handleRemoveAnswer(index)}>remove</button>
                    </div>
                  </div>
                  <div className='d-flex flex-row mb-1' key={index}>
                    <div className='pb-1 mr-2'>
                      <div style={{minWidth: '300px'}}>
                        <Select options={options} isMulti value={defaultValue} onChange={(selectedOptions) => {
                          setEditingItem(prevState => {
                            const answers = prevState.answers
                            answers[index].types = selectedOptions.map(option => option.value)
                            return { ...prevState, answers: answers}
                          })
                        }} />
                      </div>

                    </div>
                  </div>
                </div>
              )
            })}
            <div className='d-flex flex-row mb-5'>
              <button type='button' className='btn btn-outline-info btn-sm' onClick={(e) => handleAddAnswer()}>add answer</button>
            </div>
          </div>

          <button type='submit' className='btn btn-success' disabled={fetching} onClick={handleSave}>{isEditing ? 'Save' : 'Add New'}</button>
          {isEditing ? <button className='btn btn-info ml-3' onClick={() => handleCancel()}>Cancel</button> : null}
        </form>
      </div>
    )
  }
}
