/* eslint no-restricted-globals:0 */
import React, {useState} from 'react';
import _ from 'lodash';
import * as Promise from 'bluebird';
import {Container, Row, Col} from 'reactstrap';
import Loading from '../Components/Loading';
import Message from '../Lib/Message';
import useS3Upload from '../Hooks/HandleS3UploadHook';
import {upload} from '../Lib/Upload';
import Alert from '../Components/Alert';
import Head from '../Components/Head';
import {GET_SESSIONS, EDIT_SESSION, DELETE_SESSION} from '../GQL/Sessions';
import {UPLOAD} from '../GQL/Uploads';
import {DataTable, NodeForm} from '../Components/ListSession';
const {useMutation, useQuery} = require('@apollo/react-hooks');
export default function SessionsContainer() {
  const form = {
    model: {id: '', week: '', skill: '', topic: '', description: '', video: '', placeholder: ''},
    valid: false,
    dirty: false,
  };
  const {data, loading: getLoading, error} = useQuery(GET_SESSIONS);
  const [deleteItem, {loading: deleteLoading}] = useMutation(DELETE_SESSION);
  const [saveItem, {loading: saveLoading}] = useMutation(EDIT_SESSION);
  const [getUploadUrl, {loading: getUploadUrlLoading}] = useMutation(UPLOAD);
  const {loading: s3UploadLoading, handleS3Upload} = useS3Upload();
  const [message, setMessage] = useState({type: '', message: ''});
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState(form.model);
  const showMessage = Message.showMessage(setMessage);
  const showId = false;

  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem({variables: {id: item.id}, refetchQueries: ['listSessions']})
        .then(() => {
          showMessage({type: 'success', message: 'Deleted successfully.'});
        })
        .catch((err) => {
          showMessage({type: 'error', message: err.message});
        });
    }
  };

  const proccessSessionAssets = async (item) => {
    let filesUploading = [];
    if (_.isObject(item.video)) {
      filesUploading.push(item.video);
    }
    if (_.isObject(item.placeholder)) {
      filesUploading.push(item.placeholder);
    }
    await Promise.each(filesUploading, async (fileItem) => {
      const {key, file, mimetype} = fileItem;
      const {data} = await getUploadUrl({variables: {key, mimetype}});
      const {uploadUrl} = data || {};
      if (uploadUrl && uploadUrl.url && file) {
        return await handleS3Upload(uploadUrl, file, mimetype);
      }
    });
    if (_.isObject(item.video)) {
      item.video.target.value = null;
      item.video = item.video.key;
    }
    if (_.isObject(item.placeholder)) {
      item.placeholder.target.value = null;
      item.placeholder = item.placeholder.key;
    }

    return item;
  };
  const handleSave = async (event) => {
    event.preventDefault();
    let errors = [];
    console.log('save');
    console.log({editingItem});
    if (showId && !editingItem.id) {
      errors.push({field: 'id', message: 'ID is required'});
    }
    if (!editingItem.week) {
      errors.push({field: 'week', message: 'Week is required'});
    }
    if (editingItem.skill.trim().length == 0) {
      errors.push({field: 'skill', message: 'Skill is required'});
    }
    if (editingItem.topic && editingItem.topic.trim().length == 0) {
      errors.push({field: 'topic', message: 'Topic is required'});
    }
    if (editingItem.description && editingItem.description.trim().length == 0) {
      errors.push({field: 'description', message: 'Description is required'});
    }

    if (errors.length > 0) {
      let messages = errors
        .map((error) => {
          return error.message;
        })
        .join('<p/>');

      showMessage({type: 'error', message: messages});
      return;
    }
    await proccessSessionAssets(editingItem);
    saveItem({variables: {input: editingItem}, refetchQueries: ['listSessions']})
      .then((res) => {
        setIsEditing(false);
        setEditingItem(form.model);
        showMessage({type: 'info', message: 'Saved successfully'});
      })
      .catch((err) => {
        showMessage({type: 'error', message: err.message});
      });
  };
  const handleCancel = () => {
    setIsEditing(false);
    setEditingItem(form.model);
  };

  return (
    <div>
      <Head>Video Sessions</Head>
      <div className="my-3 p-3 bg-white rounded shadow-sm">
        <Container>
          <Row>
            <Col>
              <Loading
                fetching={getLoading || s3UploadLoading || deleteLoading || saveLoading || getUploadUrlLoading}
              />
              <Alert color={message.type}>{message.message}</Alert>
            </Col>
          </Row>
          <Row>
            <Col lg="5">
              <div className="pt-3 mb-0">
                <NodeForm {...{form, editingItem, setEditingItem, isEditing, handleSave, handleCancel}} />
              </div>
            </Col>
            <Col lg="7">
              <div className="media text-muted pt-3">
                <DataTable {...{items: data ? data.listSessions : [], setIsEditing, handleDelete, setEditingItem}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
