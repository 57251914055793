import {call, put, all} from 'redux-saga/effects';

export function* getMedia(api, action) {
  try {
    const urls = [
      'media/sessions/bolt-detection.mp4',
      'media/sessions/bolt-multi-size-detection.mp4',
      'media/sessions/bottle-detection.mp4',
    ];
    const urlActions = urls.map((url) => {
      return call(api.getUrl, url);
    });
    const response = yield all(urlActions);
    console.log(response);
  } catch (error) {
    console.error(error); // eslint-disable-line
  }
}
