import { call, put } from 'redux-saga/effects'
import MeditationActions from '../Redux/MeditationRedux'
import { proccessMeditationFiles } from '../Lib/Upload'

export function * deleteMeditation (api, action) {
  const { payload, resolve, reject } = action
  const { id } = payload
  yield put(MeditationActions.deleteMeditationRequest({ id }))
  try {
    yield call(api.delete, id)
    yield put(MeditationActions.deleteMeditationSuccess({ id }))
    yield call(resolve)
  } catch (error) {
    yield put(MeditationActions.deleteMeditationFailure())
    yield call(reject, error)
    console.error(error) // eslint-disable-line
  }
}
export function * saveMeditation (api, uploadApi, action) {
  const { payload, resolve, reject } = action
  const { id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files } = payload
  let record = { id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files }
  yield put(MeditationActions.saveMeditationRequest())
  try {
    //
    const proccessFilesResult = yield call(proccessMeditationFiles, uploadApi, record)
    console.log(proccessFilesResult)
    record = proccessFilesResult
    const response = yield call(api.save, record)
    const { message,
            error,
            item } = response.data.saveMeditation
    if (error) {
      throw new Error(message)
    }
    yield put(MeditationActions.saveMeditationSuccess({ payload: item }))
    yield call(resolve, item)
  } catch (error) {
    yield put(MeditationActions.saveMeditationFailure())
    console.error(error) // eslint-disable-line
    yield call(reject, error)
  }
}
