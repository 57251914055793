import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import configureStore from './CreateStore';
import rootSaga from '../Sagas/';

export const history = createBrowserHistory();
/* ------------- Assemble The Reducers ------------- */

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    startup: require('./StartupRedux').reducer,
    meditation: require('./MeditationRedux').reducer,
    routine: require('./RoutineRedux').reducer,
    routinev2: require('./RoutineReduxv2').reducer,
    node: require('./NodeRedux').reducer,
    auth: require('./AuthRedux').reducer,
    user: require('./UserRedux').reducer,
  });

export default () => {
  let finalReducers = rootReducer(history);

  let {store, sagasManager, sagaMiddleware} = configureStore(finalReducers, rootSaga, history);

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').rootReducer(history);
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require('../Sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }

  return store;
};
