import React from 'react';
import {connect} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
const NavBar = ({isLoggedIn}) => {
  if (!isLoggedIn) {
    return <div />;
  }
  return (
    <div className="nav-scroller bg-white shadow-sm">
      <nav className="nav nav-underline">
        <NavLink to="/" exact className="nav-link">
          Home
        </NavLink>
        <NavLink to="/users" className="nav-link">
          Users
        </NavLink>
        <NavLink to="/values" className="nav-link">
          Values
        </NavLink>
        <NavLink to="/routinev2" className="nav-link">
          RoutinesV2
        </NavLink>
        <NavLink to="/routine" className="nav-link">
          RoutinesV1
        </NavLink>
        <NavLink to="/feeling" className="nav-link">
          Categories
        </NavLink>
        <NavLink to="/types" className="nav-link">
          Types
        </NavLink>
        <NavLink to="/meditations" className="nav-link">
          Meditations
        </NavLink>
        <NavLink to="/sessions" className="nav-link">
          Sessions
        </NavLink>
        <NavLink to="/helps" className="nav-link">
          Helps
        </NavLink>
        <NavLink to="/waves" className="nav-link">
          Waves
        </NavLink>

        <NavLink to="/intentions" className="nav-link">
          Intentions
        </NavLink>
        <NavLink to="/quotes" className="nav-link">
          Quotes
        </NavLink>
        <NavLink to="/languages" className="nav-link">
          Languages
        </NavLink>
      </nav>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};
export default connect(mapStateToProps, null)(NavBar);
