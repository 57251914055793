import React from "react";
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

const PrivateRoute = ({ isLoggedIn, children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ location }) =>
            isLoggedIn ? (
                children
                ) : (
                <Redirect
                    to={{
                    pathname: "/login",
                    state: { from: location }
                    }}
                />
                )
            }
            />
    );
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)