import gql from 'graphql-tag';


const AuthApi = (client) => {
    return {
        me: (id) => {
            return client.query({
                query: gql`
                  query me{
                    me{
                        id,
                        username,
                        role
                    }
                  }
                `,
              })
        },
        signout: (id) => {
            return client.mutate({
                mutation: gql`
                  mutation signout{
                    signout{
                        message
                     }
                    }
                `,
              })
        },
        signin: ({username, password}) => {
            return client.mutate({
                variables: { input: {username, password}},
                mutation: gql`
                  mutation signin ($input: SigninInput!){
                    signin(input: $input){
                        message,
                        error,
                        token,
                        username,
                        user{
                            role
                        },
                        id
                    }
                    }
                `,
              })
        },
    }
}
export default AuthApi;