import React, { useState, useEffect } from 'react'
import { push } from 'connected-react-router'
import Loading from './Loading'
import Alert from './Alert'
import Message from '../Lib/Message'

const Login = ({ isLoggedIn, login, error, fetching }) => {
  const [userName, setUserName ] = useState('')
  const [password, setPassword ] = useState('')
  const [message, setMessage] = useState({type: '', message: ''})
  const showMessage = Message.showMessage(setMessage)

  useEffect(() => {
    if (isLoggedIn) push('/')
    if (error) {
      showMessage({ type: 'error', message: 'Invalid username or password'})
    }
  }, [isLoggedIn, error])

  const handleLogin = (event) => {
    event.preventDefault()
    if (!userName || !password) {
      return
    }
    login({username: userName, password})
  }

  return (
    <div>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <div className='media text-muted pt-3'>
          <form className='login-form' onSubmit={handleLogin}>
            <div className='form-group'>
              <label htmlFor='username'>Username</label>
              <input type='text' className='form-control' id='username' value={userName} onChange={(event) => setUserName(event.target.value)} />
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input type='password' className='form-control' id='password' value={password} onChange={(event) => setPassword(event.target.value)} />
            </div>
            <Loading fetching={fetching} />
            { (message.type && message.message) ? (<Alert color={message.type}>{ message.message }</Alert>) : (<span />) }
            <button type='submit' className='btn btn-primary'>Log In</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login
