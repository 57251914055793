import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {useParams} from 'react-router';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import {Container, Row, Col} from 'reactstrap';
import Head from '../Components/Head';
import Loading from '../Components/Loading';
import Alert from '../Components/Alert';
import {UserSelectors} from '../Redux/UserRedux';

const GET_USER_INFO = gql`
  query getUserInfo($userId: Int!) {
    user(userId: $userId) {
      id
      username
      firstName
      lastName
      createdAt
      updatedAt
    }
    subscriptions: getSubscriptions(userId: $userId) {
      androidSubscription {
        hasSubscription
        startDate
        endDate
        createdAt
        updatedAt
        productId
        isCancelled
      }
      iosSubscription {
        hasSubscription
        startDate
        endDate
        createdAt
        updatedAt
        productId
        isCancelled
      }
    }
  }
`;
const UserForm = ({user}) => {
  return (
    <div className="card">
      <div className="card-body">
        <form className="m-2 w-75">
          <div className="form-group row">
            <label htmlFor="id" className="col-sm-4 col-form-label">
              ID
            </label>
            <div className="col-sm-8">
              <input type="text" className={`form-control-plaintext`} readOnly id="id" value={user.id} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="email" className="col-sm-4 col-form-label">
              Email
            </label>
            <div className="col-sm-8">
              <input type="text" id="email" className={`form-control-plaintext`} readOnly value={user.username} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="firstName" className="col-sm-4 col-form-label">
              First Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                id="firstName"
                className={`form-control-plaintext`}
                readOnly
                value={user.firstName || ''}
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="lastName" className="col-sm-4 col-form-label">
              Last Name
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                id="lastName"
                className={`form-control-plaintext`}
                readOnly
                value={user.lastName || ''}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="createdAt" className="col-sm-4 col-form-label">
              Created At
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                id="createdAt"
                className={`form-control-plaintext`}
                readOnly
                value={
                  moment(user.createdAt).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a') +
                  ' ' +
                  moment(user.createdAt).tz('Etc/GMT').fromNow()
                }
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="updatedAt" className="col-sm-4 col-form-label">
              Updated At
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                id="updatedAt"
                className={`form-control-plaintext`}
                readOnly
                value={
                  moment(user.updatedAt).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a') +
                  ' ' +
                  moment(user.updatedAt).tz('Etc/GMT').fromNow()
                }
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const SubscriptionInfo = ({subscription, title}) => {
  console.log({subscription, title});
  if (!subscription || !subscription.startDate) {
    return null;
  }
  return (
    <div className="m-2 w-50">
      <div className="card">
        <div className="card-header">{title}</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            Subscription Status:{' '}
            <span className="badge badge-primary">
              {subscription.hasSubscription ? 'Active' : subscription.isCancelled ? 'Cancelled' : 'Expired'}
            </span>
          </li>
          <li className="list-group-item">Subscription Package: {subscription.productId}</li>
          <li className="list-group-item">
            Subscription Start Date: {moment(subscription.startDate).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a')} (
            {moment(subscription.startDate).tz('Etc/GMT').fromNow()})
          </li>
          <li className="list-group-item">
            Subscription End Date: {moment(subscription.endDate).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a')} (
            {moment(subscription.endDate).tz('Etc/GMT').fromNow()})
          </li>

          <li className="list-group-item">
            Created At: {moment(subscription.createdAt).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a')}
          </li>
          <li className="list-group-item">
            Updated At: {moment(subscription.updatedAt).tz('Etc/GMT').format('MMMM Do YYYY, h:mm:ss a')} (
            {moment(subscription.updatedAt).tz('Etc/GMT').fromNow()})
          </li>
        </ul>
      </div>
    </div>
  );
};

const UserInfo = (props) => {
  let {id} = useParams();
  return (
    <Query query={GET_USER_INFO} variables={{userId: parseInt(id)}}>
      {({loading, error, data}) => {
        return (
          <div>
            <Head>User Info</Head>
            <div className="my-3 p-3 bg-white rounded shadow-sm">
              <Container>
                <Row>
                  <Col>
                    <Loading fetching={loading} />
                    {error ? <Alert color="error">{error}</Alert> : <span />}
                  </Col>
                </Row>
                {data && data.user ? (
                  <Row>
                    <Col>
                      <UserForm user={data.user} />
                    </Col>
                  </Row>
                ) : null}

                {data && (data.subscriptions.iosSubscription || data.subscriptions.androidSubscription) ? (
                  <Row>
                    <Col>
                      <div className="flex">
                        <SubscriptionInfo title="Ios Subscription" subscription={data.subscriptions.iosSubscription} />
                        <SubscriptionInfo
                          title="Android Subscription"
                          subscription={data.subscriptions.androidSubscription}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col></Col>
                  </Row>
                )}
              </Container>
            </div>
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    preferredPronouns: UserSelectors.getPreferredPronouns(state),
    narrators: UserSelectors.getNarrators(state),
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
