import {takeLatest, all} from 'redux-saga/effects';
import Config from '../Config';
import API from '../Services/Api';
import StartupApi from '../Services/StartupApi';
import MeditationApi from '../Services/MeditationApi';
import RoutineApi from '../Services/RoutineApi';
import RoutineApiv2 from '../Services/RoutineApiv2';
import NodeApi from '../Services/NodeApi';
import AuthApi from '../Services/AuthApi';
import UploadApi from '../Services/UploadApi';
import UserApi from '../Services/UserApi';
import HelpApi from '../Services/HelpApi';
import MediaApi from '../Services/MediaApi';
/* ------------- Types ------------- */

import {StartupTypes} from '../Redux/StartupRedux';
import {MeditationTypes} from '../Redux/MeditationRedux';
import {RoutineTypes} from '../Redux/RoutineRedux';
import {RoutineTypesv2} from '../Redux/RoutineReduxv2';
import {NodeTypes} from '../Redux/NodeRedux';
import {AuthTypes} from '../Redux/AuthRedux';
import {UserTypes} from '../Redux/UserRedux';

/* ------------- Sagas ------------- */

import {startup} from './StartupSagas';
import {deleteMeditation, saveMeditation} from './MeditationSagas';
import {deleteQuestion, saveQuestion} from './RoutineSagas';
import {deleteQuestionv2, saveQuestionv2} from './RoutineSagasv2';
import {deleteNode, saveNode} from './NodeSagas';
import {deleteHelp, saveHelp} from './HelpSagas';
import {login, logout} from './AuthSagas';
import {fetchUsers, deleteUser} from './UserSagas';
import {getMedia} from './MediaSagas';
/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const apolloClient = API.getApolloClient();
const startupApi = StartupApi(apolloClient);
const meditationApi = MeditationApi(apolloClient);
const routineApi = RoutineApi(apolloClient);
const routineApiv2 = RoutineApiv2(apolloClient);
const nodeApi = NodeApi(apolloClient);
const helpApi = HelpApi(apolloClient);
const authApi = AuthApi(apolloClient);
const uploadApi = UploadApi(apolloClient);
const userApi = UserApi(apolloClient);
const mediaApi = MediaApi(apolloClient);

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup, startupApi),

    takeLatest(MeditationTypes.DELETE_MEDITATION, deleteMeditation, meditationApi),
    takeLatest(MeditationTypes.SAVE_MEDITATION, saveMeditation, meditationApi, uploadApi),

    takeLatest(RoutineTypes.DELETE_ROUTINE, deleteQuestion, routineApi),
    takeLatest(RoutineTypes.SAVE_ROUTINE, saveQuestion, routineApi),

    takeLatest(RoutineTypesv2.DELETE_ROUTINEV2, deleteQuestionv2, routineApiv2),
    takeLatest(RoutineTypesv2.SAVE_ROUTINEV2, saveQuestionv2, routineApiv2),

    takeLatest(NodeTypes.DELETE_NODE, deleteNode, nodeApi),
    takeLatest(NodeTypes.SAVE_NODE, saveNode, nodeApi, uploadApi),
    takeLatest(NodeTypes.SAVE_NODE, getMedia, mediaApi),
    takeLatest(NodeTypes.SAVE_HELP_NODE, saveHelp, helpApi),
    takeLatest(NodeTypes.DELETE_HELP_NODE, deleteHelp, helpApi),

    takeLatest(UserTypes.FETCH_USERS, fetchUsers, userApi),
    takeLatest(UserTypes.DELETE_USER, deleteUser, userApi),

    takeLatest(AuthTypes.LOGIN, login, authApi),
    takeLatest(AuthTypes.LOGOUT, logout),
  ]);
}
