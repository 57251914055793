import validate from 'validate.js'
import _ from 'lodash'
import isEmpty from 'lodash-es/isEmpty'

validate.validators.array = (arrayItems, itemConstraints, key, attributes, globalOptions) => {
    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        const error = validate(item, itemConstraints, globalOptions)
        if (error) errors[index] = error
        return errors
    }, {})

    return _.isEmpty(arrayItemErrors) ? null : arrayItemErrors
}

const routineConstraints = {
    question: {
        presence: { allowEmpty: false }
    },
    answers: {
        array: {
            text: {
                presence: { allowEmpty: false }
            },
            weight: {
                numericality: {
                    onlyInteger: true
                }
            },
            frequency: {
                numericality: {
                    onlyInteger: true,
                }
            },
            types: {
                presence: { allowEmpty: false }
            }
        }
    }
}
const meditationConstraints = {
    type: {
        presence: { allowEmpty: false }
    },
    frequency: {
        numericality: {
            onlyInteger: true,
        }
    },

    wave: {
        presence: { allowEmpty: false }
    },
    categories: { presence: { allowEmpty: false } },

    description: {
        presence: { allowEmpty: true }
    },
    image: {
        presence: { allowEmpty: true },
    },
    files: {
        array: {
            language: {
                presence: { allowEmpty: false }
            },
            "gender": {
                presence: { allowEmpty: false }
            },
            "url": {
                presence: { allowEmpty: false, message: "^Audio can't be blank" }
            },
            "length": {
                numericality: {
                    onlyInteger: true
                }
            }
        }
    }
}
const flatMessages = (validationResult) => {
    let messages = {}
    Object.keys(validationResult).forEach(field => {
        if (_.isArray(validationResult[field])) {
            const errors = validationResult[field];
            if (_.isArray(errors)) {
                if (_.isString(errors[0])) {
                    messages[field] = _.join(errors, '<br/>')
                }
            }
        }
    })
    return messages;
}
const formatMessages = (validationResult) => {
    let messages = {}
    Object.keys(validationResult).forEach(field => {
        if (_.isArray(validationResult[field])) {
            const errors = validationResult[field]
            if (_.isArray(errors)) {
                if (_.isString(errors[0])) {
                    messages[field] = _.join(errors, '<br/>')
                } else if (_.isObject(errors[0])) {
                    messages[field] = [];
                    Object.keys(errors[0]).forEach(index => {
                        messages[field][index] = flatMessages(errors[0][index])
                    })
                }
            }
        }
    })
    return messages;
}
const printMessage = (messages, prefixArray) => {
    let msg = [];
    Object.keys(messages).forEach(key => {
        if (_.isString(messages[key])) {
            msg.push(messages[key])
        } else if (_.isArray(messages[key])) {
            messages[key].forEach((errorItem, index) => {
                let msgAtIndex = Object.keys(errorItem).map(subKey => {
                    return errorItem[subKey]
                }).join('<br/>')
                msgAtIndex = `<strong>${prefixArray + ' ' + (index + 1) + ': '} </strong><br/>` + msgAtIndex
                msg.push(msgAtIndex)
            })
        }
    })
    return msg.join('<br/>')
}

const validateRoutineForm = (formModel) => {
    const validationResult = validate(formModel, routineConstraints);
    let messages = {};
    if (validationResult) {
        messages = formatMessages(validationResult)
    }
    let result = {
        isValid: _.isEmpty(validationResult),
        messages,
        printMessage: printMessage(messages, 'Answer')
    };
    return result;
}
const validateMeditationForm = (formModel) => {
    const validationResult = validate(formModel, meditationConstraints);
    let messages = {};
    if (validationResult) {
        messages = formatMessages(validationResult)
    }
    let result = {
        isValid: _.isEmpty(validationResult),
        messages,
        printMessage: printMessage(messages, 'File')
    };
    return result;
}
export { validate, routineConstraints, meditationConstraints, validateRoutineForm, validateMeditationForm }