import gql from 'graphql-tag';

const NodeApi = (client) => {
  return {
    delete: ({ item, nodeType }) => {
      console.log('Deleting: ' + item.id)
      return client.mutate({
        variables: { id: item.id, nodeType},
        mutation: gql`
                  mutation deleteNode ($id: ID!, $nodeType: NodeType!){
                    deleteNode(id: $id, nodeType: $nodeType){
                        message,
                        error    
                     }
                    }
                `
      })
    },
    save: ({item, nodeType}) => {
      return client.mutate({
        variables: { input: item, nodeType},
        context: {
            batch: true
        },
        mutation: gql`
                  mutation saveNode ($input: NodeInput!, $nodeType: NodeType!){
                    saveNode(input: $input, nodeType: $nodeType){
                        message,
                        error,
                        item{
                            id,
                            text,
                            image,
                            description
                        }
                    }
                    }
                `
      })
    }
  }
}
export default NodeApi
