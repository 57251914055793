const gql = require('graphql-tag');

export const UPLOAD = gql`
  mutation uploadUrl($key: String!, $mimetype: String!) {
    uploadUrl(key: $key, mimetype: $mimetype) {
      url
      fields {
        key
        acl
        mimetype
        bucket
        algorithm
        credential
        date
        policy
        signature
      }
    }
  }
`;
