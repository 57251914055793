import _ from 'lodash';

const showMessage = (setMessage) => (message) => {
    if(!_.isFunction(setMessage)){
        throw new Error('Invalid function');
    }
    setMessage.call(null, message);
    const timer = setTimeout(() => {
        setMessage.call(null, {});
    }, 4000);
}
export default { showMessage }