import gql from 'graphql-tag';

const MediaApi = (client) => {
  return {
    getUrl: (key) => {
      return client.query({
        variables: {input: {key: key}},
        context: {
          batch: true,
        },
        query: gql`
          query getMedia($input: MediaInput!) {
            media(input: $input) {
              error
              message
              url
              expires
            }
          }
        `,
      });
    },
  };
};
export default MediaApi;
