import {push} from 'connected-react-router';
import {put, select, call} from 'redux-saga/effects';
import StartupActions from '../Redux/StartupRedux';
import AuthActions from '../Redux/AuthRedux';
import MeditationActions from '../Redux/MeditationRedux';
import RoutineActions from '../Redux/RoutineRedux';
import RoutineActionsv2 from '../Redux/RoutineReduxv2';
import NodeActions from '../Redux/NodeRedux';
import {is} from 'ramda';

// process STARTUP actions
export function* startup(api, action) {
  // check user is logged in
  const token = localStorage.getItem('token');
  if (!token) {
    console.log(' redirecting to login page');
    yield put(StartupActions.initSuccess({}));
    yield put(push('/login'));
    return;
  }
  // make the call to the api
  const response = yield call(api.getInitialData);
  if (response) {
    const {
      myvalues,
      myvision,
      myreasons,
      quotes,
      // users,
      help,
      intentions,
      me,
      meditations,
      amQuestions,
      pmQuestions,
      amQuestionsv2,
      pmQuestionsv2,
      tags,
      waves,
      types,
      languages,
      categories,
    } = response.data;
    if (me.role !== 'admin') {
      yield put(AuthActions.logoutRequest());
      localStorage.removeItem('token');
      yield put(push('/login'));
      return;
    }
    yield put(AuthActions.me({...me}));
    // yield put(UserAction.initUser(users))
    yield put(MeditationActions.initMeditation(meditations || []));
    yield put(RoutineActions.initRoutine(amQuestions, pmQuestions));
    yield put(RoutineActionsv2.initRoutinev2(amQuestionsv2, pmQuestionsv2));
    yield put(
      NodeActions.initNode({
        nodeTypes: {tags, categories, waves, types, languages, myvalues, myvision, myreasons, quotes, help, intentions},
      }),
    );
    yield put(StartupActions.initSuccess({}));
  } else {
    yield put(StartupActions.initFailure());
  }
}
