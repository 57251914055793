import {connect} from 'react-redux';
import {bindActionToPromise} from '../Lib/bindActionToPromise';
import Routine from '../Components/Routine';
import {NodeSelectors} from '../Redux/NodeRedux';
import RoutineActions, {RoutineSelectors} from '../Redux/RoutineRedux';

const mapStateToProps = (state, ownProps) => {
  return {
    title: 'Routine',
    amQuestions: RoutineSelectors.selectAMQuestions(state),
    pmQuestions: RoutineSelectors.selectPMQuestions(state),
    types: NodeSelectors.selectTypes(state),
    typesById: NodeSelectors.selectTypesById(state),
    fetching: state.routine.fetching,
    error: state.routine.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionToPromise(dispatch, RoutineActions.deleteRoutine),
  saveItem: bindActionToPromise(dispatch, RoutineActions.saveRoutine),
});

const RoutineContainer = connect(mapStateToProps, mapDispatchToProps)(Routine);

export default RoutineContainer;
