import {call, put, all} from 'redux-saga/effects';
import NodeActions from '../Redux/NodeRedux';
import {proccessNodeImage} from '../Lib/Upload';

export function* deleteNode(api, action) {
  const {
    payload: {item, nodeType},
    resolve,
    reject,
  } = action;
  yield put(NodeActions.deleteNodeRequest({item, nodeType}));
  try {
    yield call(api.delete, {item, nodeType});
    yield put(NodeActions.deleteNodeSuccess({item, nodeType}));
    yield call(resolve);
  } catch (error) {
    yield put(NodeActions.deleteNodeFailure());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
export function* saveNode(api, uploadApi, action) {
  const {payload, resolve, reject} = action;
  let {item, nodeType} = payload;
  yield put(NodeActions.saveNodeRequest({item, nodeType}));
  try {
    let record = payload.item;
    const proccessFilesResult = yield call(proccessNodeImage, uploadApi, record);
    record = proccessFilesResult;

    const response = yield call(api.save, {item: record, nodeType: payload.nodeType});
    let {message, error, item} = response.data.saveNode;
    if (error) {
      throw new Error(message);
    }
    yield put(NodeActions.saveNodeSuccess({item, nodeType}));
    yield call(resolve, {item, nodeType});
  } catch (error) {
    yield put(NodeActions.saveNodeFailure());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
