import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  initNode: {nodeTypes: {}}, 
  
  deleteHelpNode: { payload: {}, resolve: null, reject: null }, 
  deleteNode: { payload: {}, resolve: null, reject: null },  
  deleteNodeRequest: {item: null, nodeType: null},
  deleteNodeSuccess: {item: null, nodeType: null},
  deleteNodeFailure: null,

  saveHelpNode: { payload: {}, resolve: null, reject: null },
  saveNode: { payload: {}, resolve: null, reject: null },
  saveNodeRequest: {item: null, nodeType: null},
  saveNodeSuccess: {item: null, nodeType: null},
  saveNodeFailure: null,
})

export const NodeTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  myvalues: {
    allIds: [],
    byId: {}
  }, 
  myreasons: {
    allIds: [],
    byId: {}
  }, 
  help: {
    allIds: [],
    byId: {}
  }, 
  intentions: {
    allIds: [],
    byId: {}
  }, 
  categories: {
    allIds: [],
    byId: {}
  }, 
  tags: {
    allIds: [],
    byId: {}
  },
  waves: {
    allIds: [],
    byId: {}
  },
  types: {
    allIds: [],
    byId: {}
  },
  languages: {
    allIds: [],
    byId: {}
  },
  quotes: {
    allIds: [],
    byId: {}
  },
})

/* ------------- Selectors ------------- */

export const NodeSelectors = {
  selectMyValues: state => state.node.myvalues.allIds.map(id => {
    return state.node.myvalues.byId[id]
  }),
  selectMyValuesById: state => state.node.myvalues.byId,

  selectMyReasons: state => state.node.myreasons.allIds.map(id => {
    return state.node.myreasons.byId[id]
  }),
  selectMyReasonsById: state => state.node.myreasons.byId,

  selectHelp: state => state.node.help.allIds.map(id => {
    return state.node.help.byId[id]
  }),
  selectIntentionsById: state => state.node.intentions.byId,
  selectIntentions: state => state.node.intentions.allIds.map(id => {
    return state.node.intentions.byId[id]
  }),
  selectHelpById: state => state.node.help.byId,
  selectCategories: state => state.node.categories.allIds.map(id => {
    return state.node.categories.byId[id]
  }),
  selectTags: state => state.node.tags.allIds.map(id => {
    return state.node.tags.byId[id]
  }),
  selectTagsOptions: state => state.node.tags.allIds.map(id => {
    let tag = state.node.tags.byId[id];
    return {
      value: tag.id,
      label: tag.text
    }
  }),
  selectWaves: state => state.node.waves.allIds.map(id => {
    return state.node.waves.byId[id]
  }),
  selectTypes: state => state.node.types.allIds.map(id => {
    return state.node.types.byId[id]
  }),
  selectQuotes: state => state.node.quotes.allIds.map(id => {
    return state.node.quotes.byId[id]
  }),

  selectLanguages: state => state.node.languages.allIds.map(id => {
    return state.node.languages.byId[id]
  }),
  selectCategoriesById: state => state.node.categories.byId,
  selectTagsById: state => state.node.tags.byId,
  selectWavesById: state => state.node.waves.byId,
  selectTypesById: state => state.node.types.byId,
  selectLanguagesById: state => state.node.languages.byId,
}

/* ------------- Reducers ------------- */

// request the data from an api
export const deleteRequest = (state, action) => {
  return state.merge({ fetching: true})
}
  
// successful api lookup
export const deleteSuccess = (state, action) => {
  let { item, nodeType } = action
  nodeType = nodeType.toLowerCase();
  return state.merge({ 
    fetching: false, 
    error: null,
    [nodeType]: { 
      ...state[nodeType], 
      allIds: state[nodeType].allIds.filter(idExisting => idExisting !== item.id) 
    }
  })
}

// Something went wrong somewhere.
export const deleteFailure = state => {
  return state.merge({ fetching: false, error: true})
}

  // request the data from an api
export const saveRequest = (state, action) => {
  return state.merge({ fetching: true})
}

// successful api lookup
export const saveSuccess = (state, action) => {
  let { item, nodeType } = action
  const entry = {
    [item.id]: item
  };
  nodeType = nodeType.toLowerCase();
  if(state[nodeType].allIds.includes(item.id)){
    // edit
    return state.merge({ 
      fetching: false, 
      error: null,
      [nodeType]: {
        ...state[nodeType],
        byId: {...state[nodeType].byId, ...entry} 
      }  
    })
  }else{
    // new
    return state.merge({ 
      fetching: false, 
      error: null,
      [nodeType]: {
        allIds: [...state[nodeType].allIds, item.id],
        byId: {...state[nodeType].byId, ...entry} 
      }
    })
  }

  }

// Something went wrong somewhere.
export const saveFailure = state => {
  return state.merge({ fetching: false, error: true})
}

export const init = (state, action) => {
    const { nodeTypes } = action
    let newState = {}
    Object.keys(nodeTypes).forEach(nodeType => {
      const tagById = nodeTypes[nodeType].reduce( (dataById, item) => (
        { ...dataById, [item.id]: item }), 
      {});
      const tagAllIds = Object.keys(tagById)
      newState[nodeType] = { byId: tagById, allIds: tagAllIds }
    })
    return state.merge(newState);
  }

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_NODE]: init,
  [Types.DELETE_NODE_REQUEST]: deleteRequest,
  [Types.DELETE_NODE_SUCCESS]: deleteSuccess,
  [Types.DELETE_NODE_FAILURE]: deleteFailure,

  [Types.SAVE_NODE_REQUEST]: saveRequest,
  [Types.SAVE_NODE_SUCCESS]: saveSuccess,
  [Types.SAVE_NODE_FAILURE]: saveFailure

})
