import React, { Component } from 'react'
export default class QuestionList extends Component {
  render () {
    const { items, onEdit, onDelete } = this.props
    return (
      <table className='table'>
        <thead>
          <tr>
            <th scope='col'>Questions</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => {
            return (<tr key={item.id}>
              <td>
                <div className='font-weight-bolder'>{item.question}</div>
                <div>
                  <a className='text-secondary mr-2' href='#' onClick={() => { onEdit(item) }}>Edit</a>
                  <a className='text-info' href='#' onClick={() => onDelete(item)}>Delete</a>
                </div>

              </td>
            </tr>)
          })}
        </tbody>
      </table>
    )
  }
}
