import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import UserForm from '../Components/UserForm'
import UserActions, { UserSelectors } from "../Redux/UserRedux";

const mapStateToProps = (state, ownProps) => {
  return {
    title: 'Edit User',
    error: state.user.error,
    fetching: state.user.fetching,
    itemsById: UserSelectors.selectById(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionToPromise(dispatch, UserActions.deleteMeditation),
  saveItem: bindActionToPromise(dispatch, UserActions.saveMeditation)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserForm)
