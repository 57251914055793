/* eslint no-restricted-globals:0 */
import React, { useState, useEffect } from 'react'
import Immutable from 'seamless-immutable'
import { WithContext as ReactTags } from 'react-tag-input'
import { Container, Row, Col, CustomInput, FormGroup, Input } from 'reactstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from 'react-router-dom'
import Loading from './Loading'
import Alert from './Alert'
import Head from './Head'

const DataList = ({ items, wavesById, typesById, tagsById, categoriesById, onEdit, onDelete }) => {
  let { path, url } = useRouteMatch()
  return (
    <table className='table'>
      <thead>
        <tr>
          <th scope='col'>#</th>
          <th scope='col'>Group</th>
          <th scope='col'>Category</th>
          <th scope='col'>Type</th>
          <th scope='col'>Frequency</th>
          
          <th scope='col'>Featured</th>
          <th scope='col' />
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => {
          let typeText = typesById[item.type] ? typesById[item.type].text : ''
          let categoryText = item.categories.map(categoryId => {
            return categoriesById[categoryId] ? categoriesById[categoryId].text : ''
          }).join(', ')
          let waveText = wavesById[item.wave] ? wavesById[item.wave].text : ''

          return (<tr key={item.id}>
            <td>{idx + 1}</td>
            <td>{item.mdgroup}</td>
            <td>{categoryText}</td>
            <td>{typeText}</td>
            <td>{item.frequency}</td>
           
            <td>{ item.featured ? 'Yes' : 'No' }</td>
            <td>
              <Link className='btn btn-secondary btn-sm mr-1' to={`${url}/edit/${item.id}`}>Edit</Link>
              <a className='btn btn-sm btn-outline-info' href='#' onClick={() => onDelete(item)}>Delete</a>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  )
}

const MediatationList = ({items, waves, types, tags, categories, wavesById, typesById, tagsById, categoriesById, categories: feeling, title, deleteItem, fetching, error, dispatch}) => {
  const [message, setMessage] = useState({type: '', message: ''})
  const onEditItem = (item) => {

  }
  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem(item).then(() => {
        setMessage({type: 'success', message: 'Deleted successfully.'})
      }).catch((err) => {
        setMessage({type: 'error', message: err.message})
      })
    }
  }

  let { path, url } = useRouteMatch()

  return (
    <div>
      <Head>{title}</Head>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <Container>
          <Row>
            <Col>
              <Loading fetching={fetching} />
              <Alert color={message.type}>{ message.message }</Alert>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to={`${url}/edit`} className='btn btn-primary float-right'>Add Meditation</Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='media text-muted pt-3'>
                <DataList {...{items, wavesById, typesById, categoriesById, tagsById, onEdit: onEditItem, onDelete: handleDelete}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default MediatationList
