import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const preferredPronouns = [
  { id: '1', title: 'He/Him' },
  { id: '2', title: 'She/Her' },
  { id: '3', title: 'They/Them' }
]
const narrators = [
  { id: '1', title: 'Male Voice' },
  { id: '2', title: 'Female Voice' }
]

const { Types, Creators } = createActions({
  fetchUsers: { keyword: '', offset: 0, limit: 5 },
  fetchUsersRequest: null,
  fetchUsersSuccess: ['data'],
  fetchUsersFailure: null,

  initUser: ['data'],
  deleteUser: { payload: {}, resolve: null, reject: null },
  deleteUserRequest: null,
  deleteUserSuccess: {id: null},
  deleteUserFailure: null,

  saveUser: { payload: {}, resolve: null, reject: null },
  saveUserRequest: null,
  saveUserSuccess: {},
  saveUserFailure: null
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  allIds: [],
  byId: {},
  totalCount: 0
})

/* ------------- Selectors ------------- */

export const UserSelectors = {
  selectUsers: state => {
    const preferredPronounsById = preferredPronouns.reduce((dataById, item) => ({ ...dataById, [item.id]: item }), {})
    const narratorsById = narrators.reduce((dataById, item) => ({ ...dataById, [item.id]: item }), {})

    const users = state.user.allIds.map(id => {
      let userById = state.user.byId[id]
      if (userById.preferredPronoun && preferredPronounsById[userById.preferredPronoun]) {
        userById = Immutable.set(userById, 'preferredPronoun', preferredPronounsById[userById.preferredPronoun])
      }
      if (userById.narrator && narratorsById[userById.narrator]) {
        userById = userById.set('narrator', narratorsById[userById.narrator])
      }
      return userById
    })
    return users
  },
  selectById: state => (state.user.byId),
  getPreferredPronouns: (state) => {
    return preferredPronouns
  },
  getNarrators: (state) => {
    return narrators
  }
}

/* ------------- Reducers ------------- */

// request the data from an api
export const deleteRequest = (state, action) => {
  return state.merge({ fetching: true})
}

// successful api lookup
export const deleteSuccess = (state, action) => {
  const { id } = action
  const allIds = state.allIds.filter(idExisting => idExisting !== id)
  return state.merge({ fetching: false, error: null, allIds })
}

// Something went wrong somewhere.
export const deleteFailure = state => {
  return state.merge({ fetching: false, error: true})
}

  // request the data from an api
export const saveRequest = (state, action) => {
  return state.merge({ fetching: true})
}

// successful api lookup
export const saveSuccess = (state, action) => {
  const { id, username, password, firstName, lastName, language, preferredPronoun, narrator } = action.payload
  const entry = {
    [id]: { id, username, password, firstName, lastName, language, preferredPronoun, narrator }
  }
  if (state.allIds.includes(id)) {
    // edit
    return state.merge({
      fetching: false,
      error: null,
      byId: {...state.byId, ...entry}
    })
  } else {
    // new
    return state.merge({
      fetching: false,
      error: null,
      allIds: [...state.allIds, id],
      byId: {...state.byId, ...entry}
    })
  }
}

// Something went wrong somewhere.
export const saveFailure = state => {
  return state.merge({ fetching: false, error: true})
}

export const fetchUsersRequest = (state, action) => {
  return state.merge({ fetching: true})
}
export const fetchUsersSuccess = (state, action) => {
  const { users, totalCount } = action.data
  const byId = users.reduce((dataById, item) => ({ ...dataById, [item.id]: item }), {})
  const allIds = users.map(({ id })=>( id ))
  return state.merge({ byId, allIds, totalCount, fetching: false })
}
export const fetchUsersFailure = state => {
  return state.merge({ fetching: false, error: true})
}

export const init = fetchUsersSuccess

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_USER]: init,
  [Types.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,

  [Types.DELETE_USER_REQUEST]: deleteRequest,
  [Types.DELETE_USER_SUCCESS]: deleteSuccess,
  [Types.DELETE_USER_FAILURE]: deleteFailure,

  [Types.SAVE_USER_REQUEST]: saveRequest,
  [Types.SAVE_USER_SUCCESS]: saveSuccess,
  [Types.SAVE_USER_FAILURE]: saveFailure

})
