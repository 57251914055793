import gql from 'graphql-tag';


const HelpApi = (client) => {
  return {
    delete: ({ item }) => {
      console.log('Deleting: ' + item.id)
      return client.mutate({
        variables: { id: item.id},
        mutation: gql`
                  mutation deleteHelp ($id: ID!){
                    deleteHelp(id: $id){
                        message,
                        error    
                     }
                    }
                `
      })
    },
    save: ({item}) => {
      return client.mutate({
        variables: { input: item},
        mutation: gql`
                  mutation saveHelp ($input: HelpInput!){
                    saveHelp(input: $input){
                        message,
                        error,
                        item{
                            id,
                            whatText,
                            whyText,
                            relevantResearchText
                        }
                    }
                    }
                `
      })
    }
  }
}
export default HelpApi
