import { call, put } from 'redux-saga/effects'
import NodeActions from '../Redux/NodeRedux'

export function * deleteHelp (api, action) {
  const { payload: { item }, resolve, reject } = action
  const nodeType = 'help';
  yield put(NodeActions.deleteNodeRequest({ item, nodeType }))
  try {
    yield call(api.delete, {item, nodeType})
    yield put(NodeActions.deleteNodeSuccess({ item, nodeType }))
    yield call(resolve)
  } catch (error) {
    yield put(NodeActions.deleteNodeFailure())
    console.error(error) // eslint-disable-line
    yield call(reject, error)
  }
}
export function * saveHelp (api, action) {
  const { payload, resolve, reject } = action
  const nodeType = 'help';
  let { item } = payload
  yield put(NodeActions.saveNodeRequest({ item, nodeType }))
  try {
    let record = payload.item
    const response = yield call(api.save, { item: record })
    let { message,
            error,
            item } = response.data.saveHelp
    if (error) {
      throw new Error(message)
    }
    yield put(NodeActions.saveNodeSuccess({ item, nodeType }))
    yield call(resolve, { item, nodeType })
  } catch (error) {
    yield put(NodeActions.saveNodeFailure())
    console.error(error) // eslint-disable-line
    yield call(reject, error)
  }
}
