import React, { useState, useEffect } from 'react'

import { Alert } from 'reactstrap'

const CustomAlert = ({ color, children }) => {
  if (color == 'error') {
    color = 'danger'
  }
  const [visible, setVisible] = useState(true)
  const onDismiss = () => setVisible(false)
  useEffect(() => {
    const element = document.getElementById('root')
    if (element && children) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [children])
  if (!children) {
    return null
  }
  return (
    <div id='custom_alert_container'>
      <Alert isOpen={visible} color={color} fade={false}>
        <div dangerouslySetInnerHTML={{__html: children }} />
      </Alert>
    </div>

  )
}

export default CustomAlert
