import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import MeditationList from '../Components/MeditationList'
import NodeActions, { NodeSelectors } from '../Redux/NodeRedux'
import MeditationActions, { MeditationSelectors } from '../Redux/MeditationRedux'

const mapStateToProps = (state, ownProps) => {
  return {
    title: 'Meditations',
    error: state.meditation.error,
    fetching: state.meditation.fetching,
    items: MeditationSelectors.selectData(state),
    tags: NodeSelectors.selectTags(state),
    categories: NodeSelectors.selectCategories(state),
    types: NodeSelectors.selectTypes(state),
    waves: NodeSelectors.selectWaves(state),

    tagsById: NodeSelectors.selectTagsById(state),
    categoriesById: NodeSelectors.selectCategoriesById(state),
    typesById: NodeSelectors.selectTypesById(state),
    wavesById: NodeSelectors.selectWavesById(state)

        // feeling: MyFeelingSelectors.selectData(state)
  }
}
const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionToPromise(dispatch, MeditationActions.deleteMeditation)
})

const MeditationListContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MeditationList)

export default MeditationListContainer
