import React from 'react'
const Loading = ({fetching}) => {
  if (!fetching) {
    return (<div />)
  }
  return (
    <div className='loading'>Loading&#8230;</div>
  )
}

export default Loading
