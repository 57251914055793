import React from 'react';
import {Switch, Route} from 'react-router-dom';

import PrivateRoute from '../Lib/PrivateRoute';
import NavBar from '../Components/NavBar';
import Header from '../Components/Header';
import Home from '../Components/Home';
import NoMatch from '../Components/NoMatch';
import Upload from '../Components/Upload';
import MyReasonsContainer from '../Containers/MyReasonsContainer';
import MyValuesContainer from '../Containers/MyValuesContainer';
import HelpContainer from '../Containers/HelpContainer';
import LoginContainer from '../Containers/LoginContainer';
import RoutineContainer from '../Containers/RoutineContainer';
import RoutineContainerv2 from '../Containers/RoutineContainerv2';
import CategoriesContainer from '../Containers/CategoriesContainer';
import SessionsContainer from '../Containers/SessionsContainer';
import UsersContainer from '../Containers/UsersContainer';
import UserFormContainer from '../Containers/UserFormContainer';
import TypesContainer from '../Containers/TypesContainer';
import QuotesContainer from '../Containers/QuotesContainer';
import WavesContainer from '../Containers/WavesContainer';
import LanguagesContainer from '../Containers/LanguagesContainer';
import IntentionsContainer from '../Containers/IntentionsContainer';
import MeditationListContainer from '../Containers/MeditationListContainer';
import MeditationFormContainer from '../Containers/MeditationFormContainer';
import UserInfoContainer from '../Containers/UserInfoContainer';

const routes = (
  <div>
    <Header />
    <NavBar />
    <main role="main" className="container" id="main_container">
      <Switch>
        <Route path="/login" component={LoginContainer} />
        <PrivateRoute path="/" exact>
          <Home />
        </PrivateRoute>
        <PrivateRoute path="/reasons">
          <MyReasonsContainer />
        </PrivateRoute>
        <PrivateRoute path="/values">
          <MyValuesContainer />
        </PrivateRoute>
        <PrivateRoute path="/sessions">
          <SessionsContainer />
        </PrivateRoute>
        <PrivateRoute path="/meditations">
          <Switch>
            <Route exact path="/meditations">
              <MeditationListContainer />
            </Route>
            <Route exact path={`/meditations/edit/:id?`}>
              <MeditationFormContainer />
            </Route>
          </Switch>
        </PrivateRoute>
        <PrivateRoute path="/feeling">
          <CategoriesContainer />
        </PrivateRoute>
        <PrivateRoute path="/helps">
          <HelpContainer />
        </PrivateRoute>
        <PrivateRoute path="/routine">
          <RoutineContainer />
        </PrivateRoute>
        <PrivateRoute path="/routinev2">
          <RoutineContainerv2 />
        </PrivateRoute>
        <PrivateRoute path="/users">
          <Switch>
            <Route exact path="/users">
              <UsersContainer />
            </Route>
            <Route exact path={`/users/view/:id?`}>
              <UserInfoContainer />
            </Route>
            <Route exact path={`/users/edit/:id?`}>
              <UserFormContainer />
            </Route>
          </Switch>
        </PrivateRoute>
        <PrivateRoute path="/types">
          <TypesContainer />
        </PrivateRoute>
        <PrivateRoute path="/waves">
          <WavesContainer />
        </PrivateRoute>
        <PrivateRoute path="/categories">
          <CategoriesContainer />
        </PrivateRoute>
        <PrivateRoute path="/quotes">
          <QuotesContainer />
        </PrivateRoute>

        <PrivateRoute path="/languages">
          <LanguagesContainer />
        </PrivateRoute>
        <PrivateRoute path="/intentions">
          <IntentionsContainer />
        </PrivateRoute>

        <PrivateRoute path="/upload">
          <Upload />
        </PrivateRoute>
        <Route component={NoMatch} />
      </Switch>
    </main>
  </div>
);

export default routes;
