import gql from 'graphql-tag';

const StartupApi = (client) => {
  return {
    getInitialData: () => {
      const INITIAL_QUERY = gql`
        query getData {
          me {
            id
            username
            role
          }
          myvalues: listNodes(nodeType: MYVALUES) {
            id
            text
          }
          myreasons: listNodes(nodeType: MYREASONS) {
            id
            text
          }
          help: helps {
            id
            whatText
            whyText
            relevantResearchText
          }
          myvision: listNodes(nodeType: MYVISION) {
            id
            text
          }
          intentions: listNodes(nodeType: INTENTIONS) {
            id
            text
          }
          types: listNodes(nodeType: TYPES) {
            id
            text
          }
          quotes: listNodes(nodeType: QUOTES) {
            id
            text
          }
          waves: listNodes(nodeType: WAVES) {
            id
            text
          }
          amQuestions: questions(type: AM) {
            id
            question
            answers {
              id
              text
              weight
              frequency
              types
            }
          }
          pmQuestions: questions(type: PM) {
            id
            question
            answers {
              id
              text
              weight
              frequency
              types
            }
          }
          amQuestionsv2: questionsv2(type: AM) {
            id
            question
            answers {
              id
              text
              weight
              frequency
              types
              range_start
              range_end
            }
          }
          pmQuestionsv2: questionsv2(type: PM) {
            id
            question
            answers {
              id
              text
              weight
              frequency
              types
              range_start
              range_end
            }
          }

          meditations {
            id
            mdgroup
            type
            categories
            tags {
              id
              text
            }
            description
            image
            frequency
            featured
            wave
            files {
              id
              length
              gender
              language
              url
            }
          }
          tags: listNodes(nodeType: TAGS) {
            id
            text
          }
          categories: listNodes(nodeType: CATEGORIES) {
            id
            text
            image
            description
          }
          languages: listNodes(nodeType: LANGUAGES) {
            id
            text
          }
        }
      `;
      return client.query({
        query: INITIAL_QUERY,
      });
    },
  };
};

export default StartupApi;
