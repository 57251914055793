import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  initRoutinev2: ['amQuestions', 'pmQuestions'],
  deleteRoutinev2: {payload: {}, resolve: null, reject: null},
  deleteRoutineRequestv2: {item: null, routine: null},
  deleteRoutineSuccessv2: {item: null, routine: null},
  deleteRoutineFailurev2: null,

  saveRoutinev2: {payload: {}, resolve: null, reject: null},
  saveRoutineRequestv2: {item: null, routine: null},
  saveRoutineSuccessv2: {item: null, routine: null},
  saveRoutineFailurev2: null,
});

export const RoutineTypesv2 = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  amQuestions: {
    allIds: [],
    byId: {},
  },
  pmQuestions: {
    allIds: [],
    byId: {},
  },
});

/* ------------- Selectors ------------- */

export const RoutineSelectors = {
  selectAMQuestions: (state) =>
    state.routinev2.amQuestions.allIds.map((id) => {
      return state.routinev2.amQuestions.byId[id];
    }),
  selectPMQuestions: (state) =>
    state.routinev2.pmQuestions.allIds.map((id) => {
      return state.routinev2.pmQuestions.byId[id];
    }),
};

/* ------------- Reducers ------------- */

// request the data from an api
export const deleteRequest = (state, action) => {
  return state.merge({fetching: true});
};

// successful api lookup
export const deleteSuccess = (state, action) => {
  const {item, routine} = action;
  if (routine.toLowerCase() == 'am') {
    return state.merge({
      fetching: false,
      error: null,
      amQuestions: {
        ...state.amQuestions,
        allIds: state.amQuestions.allIds.filter((idExisting) => idExisting !== item.id),
      },
    });
  } else {
    return state.merge({
      fetching: false,
      error: null,
      pmQuestions: {
        ...state.pmQuestions,
        allIds: state.pmQuestions.allIds.filter((idExisting) => idExisting !== item.id),
      },
    });
  }
};

// Something went wrong somewhere.
export const deleteFailure = (state) => {
  return state.merge({fetching: false, error: true});
};

// request the data from an api
export const saveRequest = (state, action) => {
  return state.merge({fetching: true});
};

// successful api lookup
export const saveSuccess = (state, action) => {
  const {item, routine} = action;
  const {id} = item;
  const entry = {};
  entry[id] = item;
  if (routine.toLowerCase() == 'am') {
    if (state.amQuestions.allIds.includes(id)) {
      // edit
      return state.merge({
        fetching: false,
        error: null,
        amQuestions: {
          ...state.amQuestions,
          byId: {...state.amQuestions.byId, ...entry},
        },
      });
    } else {
      // new
      return state.merge({
        fetching: false,
        error: null,
        amQuestions: {
          allIds: [...state.amQuestions.allIds, id],
          byId: {...state.amQuestions.byId, ...entry},
        },
      });
    }
  } else {
    if (state.pmQuestions.allIds.includes(id)) {
      // edit
      return state.merge({
        fetching: false,
        error: null,
        pmQuestions: {
          ...state.pmQuestions,
          byId: {...state.pmQuestions.byId, ...entry},
        },
      });
    } else {
      // new
      return state.merge({
        fetching: false,
        error: null,
        pmQuestions: {
          allIds: [...state.pmQuestions.allIds, id],
          byId: {...state.pmQuestions.byId, ...entry},
        },
      });
    }
  }
};

// Something went wrong somewhere.
export const saveFailure = (state) => {
  return state.merge({fetching: false, error: true});
};

export const init = (state, action) => {
  const {amQuestions, pmQuestions} = action;
  const amById = amQuestions.reduce((dataById, item) => ({...dataById, [item.id]: item}), {});
  const amAllIds = Object.keys(amById);

  const pmById = pmQuestions.reduce((dataById, item) => ({...dataById, [item.id]: item}), {});
  const pmAllIds = Object.keys(pmById);
  return state.merge({amQuestions: {byId: amById, allIds: amAllIds}, pmQuestions: {byId: pmById, allIds: pmAllIds}});
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_ROUTINEV2]: init,
  [Types.DELETE_ROUTINE_REQUESTV2]: deleteRequest,
  [Types.DELETE_ROUTINE_SUCCESSV2]: deleteSuccess,
  [Types.DELETE_ROUTINE_FAILUREV2]: deleteFailure,

  [Types.SAVE_ROUTINE_REQUESTV2]: saveRequest,
  [Types.SAVE_ROUTINE_SUCCESSV2]: saveSuccess,
  [Types.SAVE_ROUTINE_FAILUREV2]: saveFailure,
});
