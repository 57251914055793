import gql from 'graphql-tag';


const UserApi = (client) => {
  return {
    list: ({ keyword, offset, limit }) => {
      return client.query({
        variables: { keyword, offset, limit },
        query: gql`
                query users($keyword: String, $offset: Int, $limit: Int){
                    users(keyword: $keyword, offset: $offset, limit: $limit){
                        totalCount,
                        users{
                            id,
                            username,
                            firstName,
                            lastName,
                            password,
                            language,
                            preferredPronoun,
                            narrator,
                            createdAt
                        }
                    },
                }
                  `
      })
    },
    delete: (id) => {
      return client.mutate({
        variables: { input: { id } },
        mutation: gql`
                  mutation deleteUser ($input: DeleteUserInput!){
                    deleteUser(input: $input){
                        message,
                        error    
                     }
                    }
                `
      })
    }
  }
}
export default UserApi
