import React, { useState } from 'react'

import { upload, UPLOAD_URL_MUTATION } from '../Lib/Upload'
const { useMutation, useQuery, useLazyQuery } = require('@apollo/react-hooks')
const gql = require('graphql-tag')

const Upload = () => {
  const [file, setFile] = useState('')
  const [mutate] = useMutation(UPLOAD_URL_MUTATION)

  const onChange = ({
    target: {
      validity,
      files: [file]
    }
  }) => {
    if (validity.valid) {
      setFile(file)
      mutate({ variables: { key: 'media/' + file.name}}).then(({ data }) => {
        const { uploadUrl } = data || {}
        if (uploadUrl && uploadUrl.url && file) {
          console.log(uploadUrl)
          upload(uploadUrl, file).then(res => {
            console.log(res)
          }).catch(err => {
            console.error(err)
          })
        }
      })
    }
  }

  return (
    <div>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <h6 className='mb-0 lh-100'>Upload</h6>
        <div className='media text-muted pt-3'>
          <input type='file' required onChange={onChange} />
        </div>
      </div>
    </div>
  )
}
export default Upload
