import React, { useEffect, useState } from 'react'

const Pagination = ({ onPageChange, totalCount, offset, limit }) => {
  const [pageInfo, setPageInfo] = useState({totalPage: 0, currentPage: 0, range: []})

  useEffect(() => {
    let totalPage = Math.ceil(totalCount / limit)
    let currentPage = offset === 0 ? 1 : Math.floor(offset / limit) + 1
    const range = []
    for (let i = 1; i <= totalPage; ++i) {
      range.push(i)
    }
    setPageInfo({...pageInfo, totalPage, currentPage, range })
  }, [totalCount, offset, limit])

  if (pageInfo.totalPage <= 1) {
    return null
  }

  return (
    <nav>
      <ul className='pagination'>
        {
            pageInfo.range.map(page => {
              const isCurrent = page === parseInt(pageInfo.currentPage)
              const onClick = ev => {
                ev.preventDefault()
                const offset = (page === 1) ? 0 : page * limit - limit
                onPageChange(offset)
              }
              return (
                <li
                  className={isCurrent ? 'page-item active' : 'page-item'}
                  onClick={onClick}
                  key={page.toString()}>
                  <a className='page-link' href='#'>{page}</a>
                </li>
              )
            })
        }

      </ul>
    </nav>
  )
}

export default Pagination
