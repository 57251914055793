/* eslint no-restricted-globals:0 */
import React, { useState, useEffect } from 'react'
import Immutable from 'seamless-immutable'
import _ from 'lodash'
import validate from 'validate.js'
import { WithContext as ReactTags } from 'react-tag-input'
import CreatableSelect from 'react-select/creatable'
import { Container, Row, Col, CustomInput, FormGroup, Input } from 'reactstrap'
import { Collapse, Button, CardBody, Card } from 'reactstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams, useHistory,
    useRouteMatch
  } from 'react-router-dom'
import Loading from './Loading'
import Alert from './Alert'
import Head from './Head'
import Message from '../Lib/Message'
import { handleFileSelect } from '../Lib/Upload'
import { validateMeditationForm } from '../Lib/Validation'

const getIntVal = (value, defaultValue = '') => {
  let number = parseInt(value)
  number = _.isInteger(number) ? number : defaultValue
  return number
}

const FormFile = ({ form, waves, types, tags, languages, categories, isEditing, editingItem, handleCancel, feeling, handleSave, setEditingItem, handleTagDelete, handleTagAddition, handleAddFile, handleRemoveFile, suggestions, fetching, error }) => {
  const [isOpen, setIsOpen] = useState(true)
  const toggle = () => setIsOpen(!isOpen)
  const onChangeFileField = (index, field, value) => {
    setEditingItem(prevState => {
      const files = prevState.files
      files[index][field] = value
      return { ...prevState, files: files}
    })
  }
  const onUploadAudio = (index, event) => {
    let file = event.target.files[0]
    if (!file) { return }
    handleFileSelect(event, 'media').then((fileEnc) => {
      setEditingItem(prevState => {
        const files = prevState.files
        files[index].url = fileEnc
        return { ...prevState, files: files}
      })
    })
  }

  return (
    <div className='mb-3 mt-5'>
      <Button color='info' onClick={toggle} style={{ marginBottom: '1rem' }}>Files</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            {editingItem.files.map((item, index) => {
              const file = editingItem.files[index]
              if (!file) return null
              let gender = file.gender ? file.gender.toLowerCase() : ''
              let language = file.language ? file.language.toLowerCase() : ''
              return (
                <div key={index} className={index % 2 == 0 ? 'd-flex border-top bg-light pt-2 pb-2 mb-1' : 'd-flex pt-2 pb-2 mb-1'}>
                  <div className='form-group flex-fill mr-2'>
                    <label htmlFor='length'>Length</label>
                    <input type='text' style={{width: '80px'}} className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} value={editingItem.files[index].length} onChange={(e) => {
                      const val = getIntVal(e.target.value)
                      onChangeFileField(index, 'length', val)
                    }} />
                  </div>
                  <div className='form-group flex-fill mr-2'>
                    <label htmlFor='gender'>Narrator</label>
                    <select className='gender form-control' value={gender} onChange={(e) => {
                      const val = e.target.value
                      onChangeFileField(index, 'gender', val)
                    }}>
                      <option value='' />
                      <option value='male'>Male Voice</option>
                      <option value='female'>Female Voice</option>
                    </select>
                  </div>
                  <div className='form-group flex-fill mr-5'>
                    <label htmlFor='language'>Language</label>
                    <select className='language form-control' value={language} onChange={(e) => {
                      const val = e.target.value
                      onChangeFileField(index, 'language', val)
                    }}>
                      <option value='' />
                      {languages.map(language => {
                        return (
                          <option value={language.id} key={language.id}>{language.text}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='form-group flex-fill mr-2'>
                    <label>Audio</label>
                    <input type='file' className='form-control-file' onChange={(event) => onUploadAudio(index, event)} />
                    <div>{ _.isString(file.url) ? file.url : '' }</div>
                  </div>

                  <div className='form-group pt-4 pr-2'>
                    <button type='button' className={'btn btn-outline-info btn-sm'} onClick={(e) => handleRemoveFile(index)}>remove</button>
                  </div>
                </div>
              )
            })}

            <button type='button' className='btn btn-secondary mr-2' onClick={(e) => handleAddFile()}>Add File</button>

          </CardBody>
        </Card>
      </Collapse>
    </div>
  )
}

const DataForm = ({ form, waves, types, languages, tags, tagsOptions, categories, isEditing, editingItem, handleCancel, handleTagChange, feeling, handleSave, setEditingItem, handleTagDelete, handleTagAddition, handleAddFile, handleRemoveFile, suggestions, fetching, error }) => {
  const onChangeCategory = (e) => {
    const val = e.target.value
    setEditingItem(prevState => {
      const categories = [val]
      return { ...prevState, categories: categories}
    })
  }
  const onUploadImage = (event) => {
    let file = event.target.files[0]
    if (!file) { return }
    handleFileSelect(event, 'images').then((fileEnc) => {
      setEditingItem(prevState => {
        const image = fileEnc
        return { ...prevState, image: image}
      })
    })
  }

  let tagsValue = editingItem.tags.filter(tag => {
    return tag
  }).map(tag => {
    if (!tag) {
      return {}
    }
    return {value: tag.id, label: tag.text }
  })
    // tagsValue = [];

  return (
    <form className='form'>
      <div className='form-group d-none'>
        <label htmlFor='id'>ID</label>
        <input type='text' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => setEditingItem({...editingItem, id: event.target.value})} id='id' value={editingItem.id} />
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='form-group'>
            <label htmlFor='id'>Type</label>
            <select id='type' name='type' className='form-control' value={editingItem.type} onChange={(event) => setEditingItem({...editingItem, type: event.target.value})}>
              <option value=''> -- select --</option>
              {types.map(type => {
                return (
                  <option value={type.id} key={type.id}>{type.text}</option>
                )
              })}
            </select>
          </div>
        </div>
        <div className='col-6'>
          <div className='form-group'>
            <label htmlFor='frequency'>Frequency</label>
            <input type='text' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
              onChange={(event) => {
                let frequency = getIntVal(event.target.value)
                setEditingItem({...editingItem, frequency})
              }}
              id='frequency' value={editingItem.frequency} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <div className='d-flex '>
            <div className='form-group flex-fill mr-5'>
              <label htmlFor='wave'>Wave</label>
              <select id='wave' name='wave' className='form-control' value={editingItem.wave} onChange={(event) => setEditingItem({...editingItem, wave: event.target.value})}>
                <option value=''> -- select --</option>
                {waves.map(wave => {
                  return (
                    <option value={wave.id} key={wave.id}>{wave.text}</option>
                  )
                })}
              </select>
            </div>
            <div className='form-group flex-fill'>
              <label htmlFor='categories'>Categories</label>
              <select id='categories' name='categories' className='form-control' value={editingItem.categories[0]} onChange={onChangeCategory}>
                <option value=''> -- select --</option>
                {categories.map(category => {
                  return (
                    <option value={category.id} key={category.id}>{category.text}</option>
                  )
                })}
              </select>
            </div>
          </div>

        </div>
        <div className='col-6'>
            <div className='form-group mb-3'>
                <label htmlFor='mdgroup' >Group</label>
                <select id='mdgroup' name='mdgroup' className='form-control col-sm-6' value={editingItem.mdgroup} onChange={(event) => setEditingItem({...editingItem, mdgroup: event.target.value })}>
                <option value='Routine'>Routine</option>
                <option value='FullGuidance'>Full Guidance</option>
                <option value='MinimalGuidance'>Minimal Guidance</option>
                </select>
            </div>     
          <div className='form-group mb-3 d-none'>
            <label htmlFor='gender'>Tags</label>
            <CreatableSelect
              isMulti
              value={tagsValue}
              onChange={handleTagChange}
              options={tagsOptions}
                        />
          </div>
        </div>
      </div>
      
      <div className='form-group'>
        <label htmlFor='description'>Description</label>
        <textarea id='description' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => setEditingItem({...editingItem, description: event.target.value})} value={editingItem.description} rows='3' />
      </div>
      <div className='form-group'>
        <label htmlFor='image' >Image</label>
        <input type='file' className='form-control-file' id='image' onChange={onUploadImage} />
        <div>{ _.isString(editingItem.image) ? editingItem.image : '' }</div>
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='featured' >Featured</label>
        <select id='featured' name='featured' className='form-control col-sm-3' value={editingItem.featured} onChange={(event) => setEditingItem({...editingItem, featured: parseInt(event.target.value) })}>
          <option value='0'> No </option>
          <option value='1'> Yes </option>
        </select>
      </div>
      
      <FormFile {...{form, isEditing, languages, editingItem, handleCancel, feeling, handleSave, setEditingItem, handleTagDelete, handleTagAddition, handleAddFile, handleRemoveFile, suggestions, fetching, error }} />

      <button type='submit' className='btn btn-success' disabled={fetching} onClick={handleSave}>{isEditing ? 'Save' : 'Add New'}</button>
      <button className='btn btn-info ml-3' onClick={() => handleCancel()}>Cancel</button>
    </form>
  )
}

const MeditationForm = ({items, itemsById, waves, languages, types, tags, tagsOptions, categories, wavesById, typesById, tagsById, categoriesById, categories: feeling, title, saveItem, deleteItem, fetching, error, dispatch}) => {
  const emptyFile = {
    'id': '',
    'language': '',
    'gender': '',
    'url': '',
    'length': ''
  }

  const form = {
    model: {
      id: '',
      type: '',
      frequency: '',
      description: '',
      tags: [],
      wave: '',
      image: '',
      featured: 0,
      mdgroup: 'Routine',
      categories: [],
      files: [emptyFile]
    },
    valid: false,
    dirty: false
  }
  const [message, setMessage] = useState({type: '', message: ''})
  const [isEditing, setIsEditing] = useState(false)
  const [editingItem, setEditingItem] = useState(form.model)

  tagsOptions = Immutable.asMutable(tagsOptions, {deep: true})
  const showMessage = Message.showMessage(setMessage)

  let history = useHistory()
  let { id } = useParams()

  const onEditItem = (item) => {
    setIsEditing(true)
    item = Immutable.asMutable(item, {deep: true})
    setEditingItem(item)
  }

  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem(item).then(() => {
        showMessage({type: 'success', message: 'Deleted successfully.'})
      }).catch((err) => {
        showMessage({type: 'error', message: err.message})
      })
    }
  }
  const handleSave = async (event) => {
    event.preventDefault()
    fetching = true
    if (!isEditing && editingItem.id) {
      const found = items.some(item => item.id === editingItem.id)
      if (found) {
        showMessage({type: 'error', message: 'Duplicated ID'})
        return
      }
    }
    let validationResult = validateMeditationForm(editingItem)
    if (!validationResult.isValid) {
      console.log(validationResult)
            // display messages
      showMessage({type: 'error', message: validationResult.printMessage})
      return
    }

    saveItem(editingItem).then((res) => {
      setIsEditing(false)
      setEditingItem(form.model)
      showMessage({type: 'info', message: 'Saved successfully'})
      setTimeout(() => {
        gotoListPage()
      }, 3000)
    }).catch((err) => {
      showMessage({type: 'error', message: err.message})
    })
  }
  const handleTagDelete = (i) => {
    setEditingItem(prevState => {
      const tags = prevState.tags.filter((tag, index) => index !== i)
      return { ...prevState, tags: tags}
    })
  }

  const handleTagAddition = (tag) => {
    setEditingItem(prevState => {
      return { ...prevState, tags: [...prevState.tags, tag]}
    })
  }
  const handleAddFile = () => {
    editingItem.files.push(emptyFile)
    setEditingItem({...editingItem})
  }
  const handleRemoveFile = (index) => {
    editingItem.files.splice(index, 1)
    setEditingItem({...editingItem})
  }
  const handleTagChange = (newValue, actionMeta) => {
    console.group('Value Changed')
    console.log(newValue)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
    setEditingItem(prevState => {
      newValue = newValue || []
      let tags = newValue.map(tag => {
        return {
          id: tag.value,
          text: tag.label
        }
      })
      return { ...prevState, tags: tags}
    })
  }
  const handleCancel = () => {
    setIsEditing(false)
    setEditingItem(form.model)
    gotoListPage()
  }
  const gotoListPage = () => {
    history.push('/meditations')
  }
  useEffect(() => {
    if (id) {
      onEditItem(itemsById[id])
    }
    return () => {}
  }, [])

  return (
    <div>
      <Head>{title}</Head>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <Container>
          <Row>
            <Col>
              <Loading fetching={fetching} />
              { (message.type && message.message) ? (<Alert color={message.type}>{ message.message }</Alert>) : (<span />) }
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='pt-3 mb-0'>
                <DataForm {...{form, waves, types, languages, tags, tagsOptions, categories, isEditing, editingItem, handleCancel, feeling, handleSave, handleTagChange, setEditingItem, handleTagDelete, handleTagAddition, handleAddFile, handleRemoveFile, fetching, error }} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default MeditationForm
