import React from 'react'
import Version from './Version'

const Home = () => {
  return (
    <div>
      <div className='d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm'>
        <div className='lh-100'>
          <h6 className='mb-0 lh-100'>Home</h6>
        </div>
      </div>
      <Version />
    </div>
  )
}

export default Home
