import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import Login from '../Components/Login'
import { AuthSelectors } from '../Redux/AuthRedux'
import AuthActions from '../Redux/AuthRedux'

const mapStateToProps = (state, ownProps) => ({
    isLoggedIn: state.auth.isLoggedIn,
    fetching: state.auth.fetching,
    error: state.auth.error
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    login: AuthActions.login
}, dispatch)

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

export default LoginContainer
