import React from 'react'
const Head = ({children}) => {
  return (
    <div className='d-flex align-items-center p-3 my-3 bg-white rounded shadow-sm'>
      <div className='lh-100'>
        <h3 className='mb-0 lh-100'>{children}</h3>
      </div>
    </div>
  )
}

export default Head
