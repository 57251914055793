import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {bindActionToPromise} from '../Lib/bindActionToPromise';
import NodeList from '../Components/NodeListCategory';
import NodeActions, {NodeSelectors} from '../Redux/NodeRedux';

const mapStateToProps = (state, ownProps) => {
  return {
    nodeType: 'CATEGORIES',
    title: 'Meditations Categories',
    setting: {
      showId: false,
    },
    items: NodeSelectors.selectCategories(state),
    fetching: state.node.fetching,
    error: state.node.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteItem: bindActionToPromise(dispatch, NodeActions.deleteNode),
  saveItem: bindActionToPromise(dispatch, NodeActions.saveNode),
});
export default connect(mapStateToProps, mapDispatchToProps)(NodeList);
