import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import AuthActions from '../Redux/AuthRedux'
const Header = ({ logout, isLoggedIn }) => {
  return (
    <nav className='navbar navbar-expand-lg fixed-top navbar-dark bg-dark'>
      <a className='navbar-brand mr-auto mr-lg-0' href='/'>Edge Performance</a>
      <div className='navbar-collapse offcanvas-collapse'>
        <ul className='navbar-nav mr-auto' />
        { isLoggedIn && (
          <form className='form-inline my-2 my-lg-0'>
            <button className='btn btn-outline-success my-2 my-sm-0' type='button' onClick={() => logout()}>Logout</button>
          </form>
            )
            }
      </div>
    </nav>
  )
}
const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn
  }
}
const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  logout: AuthActions.logout
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Header)
