import _ from 'lodash';

const getIntVal = (value, defaultValue = '') => {
    let number = parseInt(value);
    console.log('Number parseInt: ' + number);
    number = _.isInteger(number) ? number : defaultValue;
    console.log('Number: ' + number);
    return number
}

export { getIntVal }