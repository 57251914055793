import gql from 'graphql-tag';


const UploadApi = (client) => {
  return {
    getUploadUrl: (key, mimetype) => {
      return client.mutate({
        variables: { key, mimetype},
        mutation: gql`
                mutation uploadUrl($key: String!, $mimetype: String!) {
                  uploadUrl(key: $key, mimetype: $mimetype){
                  url,
                  fields{
                      key,
                      acl,
                      mimetype,
                      bucket,
                      algorithm,
                      credential,
                      date,
                      policy,
                      signature
                  }
                  }
              }
              `
      })
    }
  }
}

export default UploadApi
