/* eslint no-restricted-globals:0 */
import React, {useState, useEffect} from 'react'
import Immutable from 'seamless-immutable'
import _ from 'lodash'
import validate from 'validate.js'
import {WithContext as ReactTags} from 'react-tag-input'
import CreatableSelect from 'react-select/creatable'
import {Container, Row, Col, CustomInput, FormGroup, Input} from 'reactstrap'
import {Collapse, Button, CardBody, Card} from 'reactstrap'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams, useHistory,
    useRouteMatch
} from 'react-router-dom'
import Loading from './Loading'
import Alert from './Alert'
import Head from './Head'
import Message from '../Lib/Message'
import {handleFileSelect} from '../Lib/Upload'
import {validateMeditationForm} from '../Lib/Validation'

const DataForm = ({form, isEditing, editingItem, handleCancel, handleSave, setEditingItem, fetching, error}) => {
  return (
    <form className='form'>
      <div className='form-group d-none'>
        <label htmlFor='id'>ID</label>
        <input type='text' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
          onChange={(event) => setEditingItem({...editingItem, id: event.target.value})} id='id'
          value={editingItem.id} />
      </div>
      <div className='form-group'>
        <label htmlFor='email'>Email</label>
        <input type='text' id='email'
          className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
          onChange={(event) => setEditingItem({...editingItem, username: event.target.value})}
          value={editingItem.username} />
      </div>
      <div className='form-group'>
        <label htmlFor='firstName'>First Name</label>
        <input type='text' id='firstName'
          className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
          onChange={(event) => setEditingItem({...editingItem, firstName: event.target.value})}
          value={editingItem.firstName} />
      </div>
      <div className='form-group'>
        <label htmlFor='lastName'>Last Name</label>
        <input type='text' id='lastName'
          className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
          onChange={(event) => setEditingItem({...editingItem, lastName: event.target.value})}
          value={editingItem.lastName} />
      </div>

      <button type='submit' className='btn btn-success' disabled={fetching}
        onClick={handleSave}>{isEditing ? 'Save' : 'Add New'}</button>
      <button className='btn btn-info ml-3' onClick={() => handleCancel()}>Cancel</button>
    </form>
  )
}

const UserForm = ({items, itemsById, title, saveItem, deleteItem, fetching, error, dispatch}) => {
  const form = {
    model: {
      id: '',
      username: '',
      firstName: '',
      lastName: '',
      language: '',
      preferredPronoun: '',
      narrator: ''
    },
    valid: false,
    dirty: false
  }
  const [message, setMessage] = useState({type: '', message: ''})
  const [isEditing, setIsEditing] = useState(false)
  const [editingItem, setEditingItem] = useState(form.model)

  const showMessage = Message.showMessage(setMessage)

  let history = useHistory()
  let {id} = useParams()

  const onEditItem = (item) => {
    setIsEditing(true)
    item = Immutable.asMutable(item, {deep: true})
    setEditingItem(item)
  }

  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem(item).then(() => {
        showMessage({type: 'success', message: 'Deleted successfully.'})
      }).catch((err) => {
        showMessage({type: 'error', message: err.message})
      })
    }
  }
  const handleSave = async (event) => {
    event.preventDefault()
    fetching = true
    if (!isEditing && editingItem.id) {
      const found = items.some(item => item.id === editingItem.id)
      if (found) {
        showMessage({type: 'error', message: 'Duplicated ID'})
        return
      }
    }
    let validationResult = validateMeditationForm(editingItem)
    if (!validationResult.isValid) {
      console.log(validationResult)
            // display messages
      showMessage({type: 'error', message: validationResult.printMessage})
      return
    }

    saveItem(editingItem).then((res) => {
      setIsEditing(false)
      setEditingItem(form.model)
      showMessage({type: 'info', message: 'Saved successfully'})
      setTimeout(() => {
        gotoListPage()
      }, 3000)
    }).catch((err) => {
      showMessage({type: 'error', message: err.message})
    })
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditingItem(form.model)
    gotoListPage()
  }
  const gotoListPage = () => {
    history.push('/users')
  }

  useEffect(() => {
    if (id) {
      onEditItem(itemsById[id])
    }
    return () => {
    }
  }, [])

  return (
    <div>
      <Head>{title}</Head>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <Container>
          <Row>
            <Col>
              <Loading fetching={fetching} />
              {(message.type && message.message) ? (
                <Alert color={message.type}>{message.message}</Alert>) : (<span />)}
            </Col>
          </Row>
          <Row>
            <Col xs='7'>
              <div className='pt-3 mb-0'>
                <DataForm {...{
                  form,
                  isEditing,
                  editingItem,
                  handleCancel,
                  handleSave,
                  setEditingItem,
                  fetching,
                  error
                }} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default UserForm
