import React, { useState, useEffect } from 'react';
import moment from 'moment'
import Loading from './Loading'
import Message from '../Lib/Message'
import Alert from './Alert'

const { useMutation, useQuery, useLazyQuery } = require('@apollo/react-hooks')
const gql = require('graphql-tag')

const GET_LAST_UPDATED = gql`
   {
    checkUpdate{
      version,
      lastUpdated,
      currentDate
    }
  }
`

const UPDATE_MUTATION = gql`
  mutation updateVersion{
    update{
      version,
      lastUpdated,
      currentDate
    }
  }
`

const Version = () => {

  const [ dataUpdated, setDataUpdated ] = useState({});
  const [mutate] = useMutation(UPDATE_MUTATION);
  const [ getDataUpdated, { loading, error, data } ] = useLazyQuery(GET_LAST_UPDATED);
  
  useEffect(() => {
    getDataUpdated();
    if (data && data.checkUpdate) {
      setDataUpdated(data.checkUpdate);
    }
},[data]);

  const handleUpdateVersion = (event) => {
    const sure = window.confirm('Are you sure you want to update?');
    if(!sure){
      return;
    }
    mutate().then( ({ data }) => {
      const { update } = data || {};
      console.log(update)
      setDataUpdated(update);
    }) 
  }
  if (loading) return <Loading fetching={loading}/>;
  if (error) return `Error! ${error.message}`;

  
  if(!dataUpdated || !dataUpdated.lastUpdated){
    return (<span/>)
  }
  return (
      <div>
          <div className="my-3 p-3 bg-white rounded shadow-sm">
              <h6 className="mb-0 lh-100">Last updated: { moment(dataUpdated.lastUpdated, "YYYY-MM-DD HH:mm:ss").from(dataUpdated.currentDate) }</h6>
              <div className="media text-muted pt-3">
                <input type="button" className="btn btn-success" value="Update" onClick={ handleUpdateVersion } />
              </div>
          </div>
      </div>
  )
}
export default Version