import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  initSuccess: {},
  initFailure: null
})

export const StartupTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  ready: false,
  fetching: null,
  error: null
})

/* ------------- Selectors ------------- */

export const StartupSelectors = {

  selectMediations: state => state.startup.mediations.mediations.allIds.map( mediationId => {
    return state.startup.mediations.mediations.byId[mediationId]
  })
}

/* ------------- Reducers ------------- */

// request
export const request = (state) =>
  state.merge({ fetching: true })

// successful
export const success = (state, action) => {
  return state.merge({
    ready: true,
    fetching: false,
    error: null,
  })
}

// failed to get data
export const failure = (state) =>
  state.merge({ fetching: false, error: true})

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_SUCCESS]: success,
  [Types.INIT_FAILURE]: failure
})
