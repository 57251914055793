import { connect } from 'react-redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import UsersList from '../Components/UsersList'
import UserActions, { UserSelectors} from '../Redux/UserRedux'
import { bindActionCreators } from 'redux'

const mapStateToProps = (state, ownProps) => {
  return {
    title: 'Users',
    preferredPronouns: UserSelectors.getPreferredPronouns(state),
    narrators: UserSelectors.getNarrators(state),
    items: UserSelectors.selectUsers(state),
    totalCount: state.user.totalCount,
    fetching: state.user.fetching,
    error: state.user.error
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: bindActionCreators(UserActions.fetchUsers, dispatch),
  deleteUser: bindActionToPromise(dispatch, UserActions.deleteUser)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersList)
