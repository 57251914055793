import {call, put} from 'redux-saga/effects';
import RoutineActions from '../Redux/RoutineReduxv2';

export function* deleteQuestionv2(api, action) {
  const {
    payload: {item, routine},
    resolve,
    reject,
  } = action;
  yield put(RoutineActions.deleteRoutineRequestv2({item, routine}));
  try {
    yield call(api.delete, {item, routine});
    yield put(RoutineActions.deleteRoutineSuccessv2({item, routine}));
    yield call(resolve);
  } catch (error) {
    yield put(RoutineActions.deleteRoutineFailurev2());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
export function* saveQuestionv2(api, action) {
  const {
    payload: {item, routine},
    resolve,
    reject,
  } = action;
  const {id, question, answers} = item;
  console.log('item: ');
  console.log({item, routine});
  yield put(RoutineActions.saveRoutineRequestv2({item, routine}));
  try {
    const response = yield call(api.save, {item: {id, question, answers}, routine});
    const {message, error, item} = response.data.saveQuestionv2;
    if (error) {
      throw new Error(message);
    }
    yield put(RoutineActions.saveRoutineSuccessv2({item, routine}));
    yield call(resolve, {item, routine});
  } catch (error) {
    yield put(RoutineActions.saveRoutineFailurev2());
    console.error(error); // eslint-disable-line
    yield call(reject, error);
  }
}
