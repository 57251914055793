import React from 'react';
import { Provider } from 'react-redux'

import { AppContainer } from 'react-hot-loader'

import { ApolloProvider } from '@apollo/react-hooks';
import createStore, { history } from './Redux'
import RootContainer from './Containers/RootContainer';
import API from './Services/Api'

// create our store
const store = createStore()
const client = API.getApolloClient();

function App() {
  return (
    <AppContainer>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <RootContainer history={history} />
        </ApolloProvider>
      </Provider>
    </AppContainer>
  );
}

export default App;
