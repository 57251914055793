import React, { useEffect } from 'react';
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import StartupActions from '../Redux/StartupRedux'
import { connect } from 'react-redux'
import routes from '../Routes'
import Loading from '../Components/Loading'

const RootContainer = ({ history, startup, ready }) => {
   useEffect(() => { startup() });
   if(!ready){
     return (
       <Loading/>
     )
   }
    return (
      <ConnectedRouter history={history}>
        { routes }
      </ConnectedRouter>
    )
  }
  
  RootContainer.propTypes = {
    history: PropTypes.object,
  }
  const mapDispatchToProps = (dispatch) => ({
    startup: () => dispatch(StartupActions.startup())
  })
  const mapStateToProps = (state, ownProps) => {
    return {
      ready: state.startup.ready
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)