import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const Meditation = {
  id: '', name: '', description: '', tags: [], categories: [], files: []
}
const { Types, Creators } = createActions({
  initMeditation: ['data'],
  deleteMeditation: { payload: Meditation, resolve: null, reject: null },
  deleteMeditationRequest: Meditation,
  deleteMeditationSuccess: Meditation,
  deleteMeditationFailure: null,

  saveMeditation: { payload: Meditation, resolve: null, reject: null },
  saveMeditationRequest: null,
  saveMeditationSuccess: {payload: Meditation },
  saveMeditationFailure: null
})

export const MeditationTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  fetching: null,
  error: null,
  allIds: [],
  byId: {}
})

/* ------------- Selectors ------------- */

export const MeditationSelectors = {
  selectData: state => state.meditation.allIds.map(id => {
    return state.meditation.byId[id]
  }),
  selectById: state => state.meditation.byId
}

/* ------------- Reducers ------------- */

// request the data from an api
export const deleteRequest = (state, action) => {
  return state.merge({ fetching: true})
}

// successful api lookup
export const deleteSuccess = (state, action) => {
  const { id } = action
  const allIds = state.allIds.filter(idExisting => idExisting !== id)
  return state.merge({ fetching: false, error: null, allIds })
}

// Something went wrong somewhere.
export const deleteFailure = state => {
  return state.merge({ fetching: false, error: true})
}

  // request the data from an api
export const saveRequest = (state, action) => {
  return state.merge({ fetching: true})
}

// successful api lookup
export const saveSuccess = (state, action) => {
  const { id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files } = action.payload
  const entry = {
    [id]: { id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files }
  }
  if (state.allIds.includes(id)) {
    // edit
    return state.merge({
      fetching: false,
      error: null,
      byId: {...state.byId, ...entry}
    })
  } else {
    // new
    return state.merge({
      fetching: false,
      error: null,
      allIds: [...state.allIds, id],
      byId: {...state.byId, ...entry}
    })
  }
}

// Something went wrong somewhere.
export const saveFailure = state => {
  return state.merge({ fetching: false, error: true})
}

export const init = (state, action) => {
  const { data } = action
  const byId = data.reduce((dataById, item) => ({ ...dataById, [item.id]: item }), {})
  const allIds = Object.keys(byId)
  return state.merge({ byId, allIds })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INIT_MEDITATION]: init,
  [Types.DELETE_MEDITATION_REQUEST]: deleteRequest,
  [Types.DELETE_MEDITATION_SUCCESS]: deleteSuccess,
  [Types.DELETE_MEDITATION_FAILURE]: deleteFailure,

  [Types.SAVE_MEDITATION_REQUEST]: saveRequest,
  [Types.SAVE_MEDITATION_SUCCESS]: saveSuccess,
  [Types.SAVE_MEDITATION_FAILURE]: saveFailure

})
