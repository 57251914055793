import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, split } from 'apollo-link';
import { BatchHttpLink } from "apollo-link-batch-http";
import { RetryLink } from "apollo-link-retry";
import Config from '../Config'

const create = ({ baseURL, apiKey, appId }) => {
  const cache = new InMemoryCache({
    addTypename: false
  });
  const uri = baseURL;
  const batchHttpLink = new BatchHttpLink({ uri, 
    batchInterval: 10
 });
  const httpLink = new HttpLink({ uri });
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true
    },
    attempts: {
      max: 3,
      retryIf: (error, _operation) => !!error
    }
  });
  const authMiddleware = setContext(async (request, ctx) => {
    const { headers } = ctx;
    const token = await localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        'x-api-key': apiKey,
        'x-app-id': appId,
        authorization: token ? `Bearer ${token}` : '',
      }
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      split(
        operation => operation.getContext().batch === true,
        ApolloLink.from([retryLink, authMiddleware, batchHttpLink]),
        ApolloLink.from([retryLink, authMiddleware, httpLink])
      )
    ]),
    cache
  });
  return { client }
}
const getApolloClient = () => {
  const { api: { url: baseURL, apiKey, appId } } = Config;
  const { client } = create({ baseURL, apiKey, appId } )
  return client;
}

export default {
  create,
  getApolloClient
}
