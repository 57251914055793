const gql = require('graphql-tag');

export const GET_SESSIONS = gql`
  query listSessions {
    listSessions {
      id
      week
      topic
      description
      skill
      video
      placeholder
    }
  }
`;

export const EDIT_SESSION = gql`
  mutation saveSession($input: SessionInput!) {
    saveSession(input: $input) {
      error
      message
      item {
        id
        week
        skill
        topic
        description
        video
        placeholder
      }
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation deleteSession($id: ID!) {
    deleteSession(id: $id) {
      error
      message
    }
  }
`;
