import _ from "lodash";
import local from "./local";
import development from "./development";
import staging from "./staging";
import production from "./production";

const defaultConfig = {
    api: {
        apiKey: "332dace0-e629-48bf-b664-d550ebfc828c",
        appId: "52557818-b027-43a8-bf45-fb87f98cd96e",
    },
};
const env = process.env.REACT_APP_STAGE || "local";

const envConfig = {
    local,
    development,
    staging,
    production,
};
const config = _.merge(defaultConfig, envConfig[env]);
export default {
    ...config,
};
