import gql from 'graphql-tag';


const MeditationApi = (client) => {
  return {
    delete: (id) => {
      return client.mutate({
        variables: { id: id},
        mutation: gql`
                  mutation deleteMeditation ($id: ID!){
                    deleteMeditation(id: $id){
                        message,
                        error    
                     }
                    }
                `
      })
    },
    getUploadUrl: (key, mimetype) => {
      return client.mutate({
        variables: { key, mimetype},
        mutation: gql`
                mutation uploadUrl($key: String!, $mimetype: String!) {
                  uploadUrl(key: $key, mimetype: $mimetype){
                  url,
                  fields{
                      key,
                      acl,
                      mimetype,
                      bucket,
                      algorithm,
                      credential,
                      date,
                      policy,
                      signature
                  }
                  }
              }
              `
      })
    },
    save: ({ id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files }) => {
      return client.mutate({
        variables: { input: { id, mdgroup, type, frequency, featured, wave, image, description, tags, categories, files }},
        mutation: gql`
                  mutation saveMeditation ($input: MeditationInput!){
                    saveMeditation(input: $input){
                        message,
                        error,
                        item{
                            id, mdgroup, type, frequency, featured, wave, image, description, categories,
                            tags{
                              id, text
                            },
                            files{
                              id,
                              length,
                              gender,
                              language,
                              url
                            }
                        }
                    }
                    }
                `
      })
    }
  }
}
export default MeditationApi
