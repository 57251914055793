import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  login: {username: null, password: null},
  loginRequest: null,
  loginSuccess: {id: null, username: null, },
  loginFailure: { error: null, message: null },

  me: { id: null, username: null },

  logout: null,
  logoutRequest: null,
  logoutSuccess: null
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  user: {},
  isLoggedIn: false,
  fetching: null,
  error: null,
  message: ''
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  getUser: state => state.auth.user,
  isLoggedIn: state => state.auth.isLoggedIn
}

/* ------------- Reducers ------------- */

// request the data from an api
export const loginRequest = (state) => {
  return state.merge({ fetching: true, error: null})
}
  
// successful api lookup
export const loginSuccess = (state, action) => {
  const { id, username } = action
  return state.merge({ fetching: false, error: null, user: {id, username}, isLoggedIn: true })
}

// Something went wrong somewhere.
export const loginFailure = (state, action) => {
  const { error, message } = action
  return state.merge({ fetching: false, error: true, message})
}
  
export const logoutRequest = (state) => {
  return state.merge({ error: null, user: {}, isLoggedIn: false })
}

export const me = (state, action) => {
  const { id, username } = action
  return state.merge({user: {id, username}, isLoggedIn: id ? true : false })
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ME]: me,
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,

  [Types.LOGOUT_REQUEST]: logoutRequest,
})
