import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { bindActionToPromise } from '../Lib/bindActionToPromise'
import NodeList from '../Components/NodeList'
import NodeActions, { NodeSelectors } from '../Redux/NodeRedux'

const mapStateToProps = (state, ownProps) => {
    return {
        nodeType: 'WAVES',
        title: 'Waves',
        setting: {
            showId: false
        },
        items: NodeSelectors.selectWaves(state),
        fetching: state.node.fetching,
        error: state.node.error
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteItem: bindActionToPromise(dispatch, NodeActions.deleteNode),
    saveItem: bindActionToPromise(dispatch, NodeActions.saveNode)
})
const WavesContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(NodeList)

export default WavesContainer