import React from 'react'

const NoMatch = () => {
  return (
    <div>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <h6 className='mb-0 lh-100'>Page Not Found</h6>
        <div className='media text-muted pt-3'>
                    The page you requested could not be found.
                </div>
      </div>
    </div>
  )
}

export default NoMatch
