import gql from 'graphql-tag';


const RoutineApi = (client) => {
    return {
        delete: ( { item, routine } ) => {
            return client.mutate({
                variables: { id: item.id, type: routine},
                mutation: gql`
                  mutation deleteQuestion ($id: ID!, $type: QuestionType!){
                    deleteQuestion(id: $id, type: $type){
                        message,
                        error    
                     }
                    }
                `,
              })
        },
        save: ({ item: {id, question, answers }, routine}) => {
            return client.mutate({
                variables: { input: {id, question, answers }, type: routine},
                mutation: gql`
                  mutation saveQuestion($input: QuestionInput!, $type: QuestionType!){
                    saveQuestion(input: $input, type: $type){
                        message,
                        error,
                        item{
                            id,
                            question
                            answers{
                              id,
                              text,
                              frequency,
                              weight,
                              types
                            }
                        }
                    }
                    }
                `,
              })
        },
    }
}
export default RoutineApi;