import { push } from 'connected-react-router'
import StartupActions from '../Redux/StartupRedux'
import { call, put } from 'redux-saga/effects'
import AuthActions from '../Redux/AuthRedux'
import { AuthSelectors } from '../Redux/AuthRedux'

export function * login (api, action) {
  const { username, password } = action
  yield put(AuthActions.loginRequest());
  try{
    const response = yield call(api.signin, { username, password })
    if(response){
      const { error, message, token, username, id, user } = response.data.signin
      if(error){
        throw Error(message)
      }
      if(token && user && user.role === 'admin'){
        localStorage.setItem('token', token)
        yield put(AuthActions.loginSuccess({ username, id }))
        yield put(StartupActions.startup())
        yield put(push('/'))
      }else{
        yield put(AuthActions.loginFailure({error, message: 'Invalid username or password'}))
      }

    }else{
      throw Error('Invalid Response')
    }
    
  }catch( error ){
    yield put(AuthActions.loginFailure({error, message: error.message}))
  }
}

export function * logout (api, action) {
  yield put(AuthActions.logoutRequest())
  localStorage.removeItem('token')
  yield put(push('/login'))
}
