/* eslint no-restricted-globals:0 */
import React, {useState} from 'react';
import _ from 'lodash';
import {Input} from 'reactstrap';
import {handleFileSelect} from '../Lib/Upload';

const NodeForm = ({form, editingItem, setEditingItem, isEditing, handleSave, handleCancel}) => {
  const onUploadVideo = (event) => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    handleFileSelect(event, 'media/sessions').then((fileEnc) => {
      setEditingItem((prevState) => {
        return {...prevState, video: fileEnc};
      });
    });
  };

  const onUploadImage = (event) => {
    let file = event.target.files[0];
    if (!file) {
      return;
    }
    handleFileSelect(event, 'images/sessions').then((fileEnc) => {
      setEditingItem((prevState) => {
        return {...prevState, placeholder: fileEnc};
      });
    });
  };
  return (
    <form className="form" onSubmit={handleSave}>
      <div className={`form-group d-none`}>
        <label htmlFor="id">ID</label>
        <input
          type="text"
          readOnly
          className={`form-control ${form.dirty && !form.valid ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
            setEditingItem({id: value, ...editingItem});
          }}
          id="id"
          value={editingItem.id}
        />
      </div>
      <div className={`form-group`}>
        <label htmlFor="id">Week</label>
        <input
          type="text"
          readOnly
          className={`form-control ${form.dirty && !form.valid ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
            setEditingItem({...editingItem, week: event.target.value});
          }}
          id="id"
          value={editingItem.week}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="text">Skill</label>
        <Input
          type="text"
          name="text"
          id="text"
          className={`form-control ${form.dirty && !form.valid ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value;
            setEditingItem({...editingItem, skill: value});
          }}
          value={editingItem.skill}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="text">Topic</label>
        <Input
          type="text"
          name="text"
          id="text"
          className={`form-control ${form.dirty && !form.valid ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value;
            setEditingItem({...editingItem, topic: value});
          }}
          value={editingItem.topic}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="text">Descriptions</label>
        <Input
          type="textarea"
          name="text"
          id="text"
          rows="10"
          className={`form-control ${form.dirty && !form.valid ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value;
            setEditingItem({...editingItem, description: value});
          }}
          value={editingItem.description || ''}
        />
      </div>
      <div className="form-group">
        <label htmlFor="video">Video</label>
        <input type="file" className="form-control-file" id="video" onChange={onUploadVideo} />
        <div>{_.isString(editingItem.video) ? editingItem.video : ''}</div>
      </div>
      <div className="form-group">
        <label htmlFor="placeholder">Image Placeholder</label>
        <input type="file" className="form-control-file" id="placeholder" onChange={onUploadImage} />
        <div>{_.isString(editingItem.placeholder) ? editingItem.placeholder : ''}</div>
      </div>

      <button type="submit" className="btn btn-success" disabled={isEditing ? false : true}>
        {isEditing ? 'Save' : 'Add New'}
      </button>
      {isEditing ? (
        <button className="btn btn-info ml-3" onClick={() => handleCancel()}>
          Cancel
        </button>
      ) : null}
    </form>
  );
};
const DataTable = ({items, setIsEditing, handleDelete, setEditingItem}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Week</th>
          <th scope="col">Skill</th>
          <th scope="col">Topic</th>
          <th scope="col">Description</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.week}</td>
              <td>{item.skill}</td>
              <td>{item.topic}</td>
              <td>{_(item.description).truncate(8)}</td>
              <td style={{minWidth: '130px'}}>
                <a
                  className="btn btn-secondary btn-sm mr-1"
                  onClick={() => {
                    setIsEditing(true);
                    setEditingItem(item);
                  }}
                >
                  Edit
                </a>
                <a className="btn btn-sm btn-outline-info d-none" onClick={() => handleDelete(item)}>
                  Delete
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export {DataTable, NodeForm};
