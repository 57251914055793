/* eslint no-restricted-globals:0 */
import React, { useState } from 'react'
import _ from 'lodash'
import { Container, Row, Col, Input } from 'reactstrap'
import Loading from './Loading'
import Message from '../Lib/Message'
import Alert from './Alert'
import Head from './Head'
import { handleFileSelect } from '../Lib/Upload'

const NodeForm = ({ form, nodeType, setting, editingItem, setEditingItem, isEditing, handleSave, handleCancel }) => {
  const onUploadImage = (event) => {
    let file = event.target.files[0]
    if (!file) { return }
    handleFileSelect(event, 'images').then((fileEnc) => {
      setEditingItem(prevState => {
        const image = fileEnc
        return { ...prevState, image: image}
      })
    })
  }
  console.log(' -- editingItem -- ')
  console.log(editingItem)
  return (
    <form className='form' onSubmit={handleSave}>
      <div className={`form-group`}>
        <label htmlFor='id'>ID</label>
        <input type='text'
          readOnly={isEditing}
          className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`}
          onChange={(event) => {
            let value = event.target.value.replace(/[^a-zA-Z0-9]/g, '')
            setEditingItem({id: value, text: editingItem.text})
          }
                        }
          id='id'
          value={editingItem.id}
                        />
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='text'>Text</label>
        <Input type='text' name='text' id='text' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => {
          let value = event.target.value
          setEditingItem({...editingItem, text: value})
        }} value={editingItem.text} />
      </div>
      <div className='form-group'>
        <label htmlFor='image' >Image</label>
        <input type='file' className='form-control-file' id='image' onChange={onUploadImage} />
        <div>{ _.isString(editingItem.image) ? editingItem.image : '' }</div>
      </div>
      <div className='form-group mb-3'>
        <label htmlFor='text'>Description</label>
        <Input type='textarea' name='description' id='description' className={`form-control ${(form.dirty && !form.valid) ? ' is-invalid' : ''}`} onChange={(event) => {
          let description = event.target.value
          setEditingItem({...editingItem, description})
        }} value={editingItem.description ? editingItem.description : ''} />
      </div>
      <button type='submit' className='btn btn-success'>{isEditing ? 'Save' : 'Add New'}</button>
      {isEditing ? <button className='btn btn-info ml-3' onClick={() => handleCancel()}>Cancel</button> : null}
    </form>
  )
}
const DataTable = ({ items, setIsEditing, handleDelete, setEditingItem}) => {
  return (
    <table className='table'>
      <thead>
        <tr>
          <th scope='col'>ID</th>
          <th scope='col'>Text</th>
          <th scope='col' />
        </tr>
      </thead>
      <tbody>
        {items.map(item => {
          return (<tr key={item.id}>
            <td>{item.id}</td>
            <td>{_.truncate(item.text, { length: 200}) }</td>
            <td style={{minWidth: '130px'}}>
              <a className='btn btn-secondary btn-sm mr-1' onClick={() => { setIsEditing(true); setEditingItem(item) }}>Edit</a>
              <a className='btn btn-sm btn-outline-info' href='#' onClick={() => handleDelete(item)}>Delete</a>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  )
}
const NodeList = ({items, nodeType, setting, title, saveItem, deleteItem, fetching}) => {
  const form = {
    model: {id: '', text: '', image: '', description: ''},
    valid: false,
    dirty: false
  }
  const [message, setMessage] = useState({type: '', message: ''})
  const [isEditing, setIsEditing] = useState(false)
  const [editingItem, setEditingItem] = useState(form.model)
  const showMessage = Message.showMessage(setMessage)
  const showId = _.get(setting, 'showId', true)

  const handleDelete = (item) => {
    if (confirm('Do you want to delete ?')) {
      deleteItem({ item, nodeType }).then(() => {
        showMessage({type: 'success', message: 'Deleted successfully.'})
      }).catch((err) => {
        showMessage({type: 'error', message: err.message})
      })
    }
  }
  const validate = () => {
    let errors = []
    if (showId && !editingItem.id) {
      errors.push({field: 'id', message: 'ID is required'})
    }
    if (editingItem.text.trim().length == 0) {
      errors.push({field: 'text', message: 'Text is required'})
    }
    if (!isEditing && editingItem.id) {
      const found = items.some(item => item.id === editingItem.id)
      if (found) {
        errors.push({field: '', message: 'Duplicated ID'})
      }
    }
    if (errors.length > 0) {
      let messages = errors.map(error => {
        return error.message
      }).join('<p/>')

      showMessage({type: 'error', message: messages})
    }
  }
  const handleSave = (event) => {
    event.preventDefault()
    let errors = []
    if (showId && !editingItem.id) {
      errors.push({field: 'id', message: 'ID is required'})
    }
    if (editingItem.text.trim().length == 0) {
      errors.push({field: 'text', message: 'Text is required'})
    }
    if (!isEditing && editingItem.id) {
      const found = items.some(item => item.id === editingItem.id)
      if (found) {
        errors.push({field: '', message: 'Duplicated ID'})
      }
    }
    if (errors.length > 0) {
      let messages = errors.map(error => {
        return error.message
      }).join('<p/>')

      showMessage({type: 'error', message: messages})
      return
    }

    saveItem({item: editingItem, nodeType }).then((res) => {
      setIsEditing(false)
      setEditingItem(form.model)
      showMessage({type: 'info', message: 'Saved successfully'})
    }).catch((err) => {
      showMessage({type: 'error', message: err.message})
    })
  }
  const handleCancel = () => {
    setIsEditing(false)
    setEditingItem(form.model)
  }

  return (
    <div>
      <Head>{title}</Head>
      <div className='my-3 p-3 bg-white rounded shadow-sm'>
        <Container>
          <Row>
            <Col>
              <Loading fetching={fetching} />
              <Alert color={message.type}>{ message.message }</Alert>
            </Col>
          </Row>
          <Row>
            <Col lg='5'>
              <div className='pt-3 mb-0'>
                <NodeForm {...{form, editingItem, nodeType, setting, setEditingItem, isEditing, handleSave, handleCancel}} />
              </div>
            </Col>
            <Col lg='7'>
              <div className='media text-muted pt-3'>
                <DataTable {...{ items, setIsEditing, handleDelete, setEditingItem}} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default NodeList
