import gql from 'graphql-tag';

const RoutineApi = (client) => {
  return {
    delete: ({item, routine}) => {
      return client.mutate({
        variables: {id: item.id, type: routine},
        mutation: gql`
          mutation deleteQuestionv2($id: ID!, $type: QuestionType!) {
            deleteQuestionv2(id: $id, type: $type) {
              message
              error
            }
          }
        `,
      });
    },
    save: ({item: {id, question, answers}, routine}) => {
      return client.mutate({
        variables: {input: {id, question, answers}, type: routine},
        mutation: gql`
          mutation saveQuestionv2($input: QuestionInput!, $type: QuestionType!) {
            saveQuestionv2(input: $input, type: $type) {
              message
              error
              item {
                id
                question
                answers {
                  id
                  text
                  frequency
                  weight
                  types
                  range_start
                  range_end
                }
              }
            }
          }
        `,
      });
    },
  };
};
export default RoutineApi;
